import { BASE_COLORS, FONT_SIZE, FONT_WEIGHT } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

export const DetailName = styled.h1`
  font-size: ${FONT_SIZE.xl};
  font-weight: ${FONT_WEIGHT.bold};
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

export const TitleText = styled(BaseTypography.Title)`
  &.ant-typography {
    margin-bottom: 0.5rem;
    color: var(--text-main-color);

    font-size: ${FONT_SIZE.md};
  }
`;

export const Input = styled(AntInput)`
  .ant-input-group-addon:first-child,
  .ant-input-group-addon:last-child {
    min-width: 5.5rem;
    color: var(--primary-color);
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.lg};
  }

  .ant-input-group-addon .ant-select {
    .ant-select-selection-item {
      min-width: 5.5rem;
      color: var(--primary-color);
      font-weight: ${FONT_WEIGHT.semibold};
      font-size: ${FONT_SIZE.lg};
    }
  }

  .ant-select-arrow {
    color: var(--disabled-color);
  }
  background-color: ${BASE_COLORS.white} !important;
  color: ${BASE_COLORS.black} !important;
`;

export const Total = styled.div`
  position: absolute;
  bottom: 0;
  font-size: ${FONT_SIZE.xl};
`;
