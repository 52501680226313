import React from 'react';
import { SecurityCodeForm } from '@app/components/auth/SecurityCodeForm/SecurityCodeForm';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import AuthLayout from '@app/components/layouts/AuthLayout/AuthLayout';

const SecurityCodePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <>
        <PageTitle>{t('common.securityCode')}</PageTitle>
        <SecurityCodeForm />
      </>
    </AuthLayout>
  );
};

export default SecurityCodePage;
