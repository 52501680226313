import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import BaseLayout from '@app/components/layouts/BaseLayout';
import LayoutDetailPage from '@app/components/orders/orderDetail/DetailLayout';

function OrderDetailPage() {
  return (
    <BaseLayout>
      <>
        <PageTitle>Detail Order</PageTitle>
        <LayoutDetailPage></LayoutDetailPage>
      </>
    </BaseLayout>
  );
}

export default OrderDetailPage;
