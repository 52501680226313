import React, { useEffect } from 'react';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInput } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { userDataSelector } from '@app/store/slices/userSlice';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';

export type InfoUserFormData = {
  email: string;
  fullname: string;
  balance: number;
  status: string;
  role: string;
};

interface InfoUserModalProps {
  title: string;
  isOpen: boolean;
  onCancel: () => void;
}

const initialValues: InfoUserFormData = {
  fullname: '',
  email: '',
  balance: 0,
  status: '',
  role: '',
};

export const InfoUserModal: React.FC<InfoUserModalProps> = ({ title, isOpen, onCancel }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  const { userManager } = useAppSelector(userDataSelector);
  const { dataDetail, loadingDetail } = userManager;

  useEffect(() => {
    if (dataDetail) form.setFieldsValue(dataDetail);

    return () => {
      form.resetFields();
      form.setFieldsValue(initialValues);
    };
  }, [dataDetail, form]);

  const handleCancel = () => {
    onCancel();
    form.setFieldsValue(initialValues);
    form.resetFields();
  };
  return (
    <BaseModal title={title} width={500} open={isOpen} onCancel={handleCancel} footer={null} destroyOnClose>
      <BaseSpin spinning={loadingDetail}>
        <BaseForm form={form} name="infoUser" validateTrigger="onBlur" initialValues={initialValues}>
          <BaseForm.Item name="fullname" label={t('common.fullname')}>
            <FormInput placeholder={t('common.fullname')} readOnly />
          </BaseForm.Item>

          <BaseForm.Item name="email" label={t('common.email')}>
            <FormInput placeholder={t('common.email')} readOnly />
          </BaseForm.Item>

          <BaseForm.Item label="Balance" name="balance">
            <InputNumber block type="number" addonBefore={<>USD</>} readOnly />
          </BaseForm.Item>

          <BaseForm.Item label="Status" name="status">
            <FormInput placeholder="Status" readOnly />
          </BaseForm.Item>

          <BaseForm.Item label="Role" name="role">
            <FormInput placeholder="Role" readOnly />
          </BaseForm.Item>
        </BaseForm>
      </BaseSpin>
    </BaseModal>
  );
};
