import React from 'react';
import { Outlet, createBrowserRouter, RouterProvider, redirect, LoaderFunctionArgs } from 'react-router-dom';
import axios from 'axios';

// no lazy loading for auth pages to avoid flickering
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import VerifyAccountPage from '@app/pages/VerifyAccountPage';
import { VerifyPage } from '../auth/VerifyAccountForm/VerifyTeamplate';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import TermsPage from '@app/pages/TermsPage';
import PolicyPage from '@app/pages/PolicyPage';
import OrderDetailPage from '@app/pages/OrderDetailPage';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import NftDashboardPage from '@app/pages/DashboardPages/NftDashboardPage';
import CategoryPage from '@app/pages/catalogPages/CategoryPage';
import ProductPage from '@app/pages/catalogPages/productPages/ProductPage';
import NewProductPage from '@app/pages/catalogPages/productPages/NewProductPage';
import EditProductPage from '@app/pages/catalogPages/productPages/EditProductPage';
import OrderPage from '@app/pages/OrderPage';
import BillingPage from '@app/pages/TopUpPages/BillingPage';
import TicketsPage from '@app/pages/TicketPages/TicketPage';
import DetailTicketPage from '@app/pages/TicketPages/DetailTicket';
import { RequireRole } from './RequireRole';
import HistoryPage from '@app/pages/History';
import UserManagerPage from '@app/pages/UserManager';
import DesignMakerPage from '@app/pages/DesignMaker';
import ProtectedRoute from '@app/pages/ProtectedRoute';
import { readToken } from '@app/services/localStorage.service';
import MyConfigsPage from '@app/pages/MyConfigs';

const ProductDetailPage = React.lazy(() => import('@app/pages/catalogPages/productPages/DetailProduct'));
const Logout = React.lazy(() => import('./Logout'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfoPage'));
const SecuritySettingsPage = React.lazy(() => import('@app/pages/SecuritySettingsPage'));
const NotificationsPage = React.lazy(() => import('@app/pages/NotificationsPage'));
const PaymentsPage = React.lazy(() => import('@app/pages/PaymentsPage'));

/** ------TODO: ------ */
// const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
// const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
// const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
// const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
// const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
// const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
// const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
// const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
// const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
// const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
// const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
// const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
// const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
// const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
// const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
// const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
// const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
// const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
// const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
// const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
// const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
// const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
// const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
// const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
// const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
// const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
// const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
// const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
// const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
// const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
// const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
// const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));
// const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
// const DataTablesPage = React.lazy(() => import('@app/pages/DataTablesPage'));
// const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
/** ------TODO: ------ */

export const NFT_DASHBOARD_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';
export const BILLING_PATH = '/billing';
export const PREFIX_ORDER_DETAIL = '/orders/detail/';

const NftDashboard = withLoading(NftDashboardPage);
const Category = withLoading(CategoryPage);
const Product = withLoading(ProductPage);
const DesignMaker = withLoading(DesignMakerPage);
const History = withLoading(HistoryPage);
const UserManager = withLoading(UserManagerPage);
const Billing = withLoading(BillingPage);
const Tickets = withLoading(TicketsPage);
const NewProduct = withLoading(NewProductPage);
const UpdateProduct = withLoading(EditProductPage);
const ProductDetail = withLoading(ProductDetailPage);
const Terms = withLoading(TermsPage);
const Policy = withLoading(PolicyPage);
const OrderDetail = withLoading(OrderDetailPage);
const DetailTicket = withLoading(DetailTicketPage);

/* ------TODO: ------*/
// const NewsFeed = withLoading(NewsFeedPage);
// const AdvancedForm = withLoading(AdvancedFormsPage);
// const MedicalDashboard = withLoading(MedicalDashboardPage);
// const DataTables = withLoading(DataTablesPage);
// const Charts = withLoading(ChartsPage);

// Maps
// const Google = withLoading(GoogleMaps);
// const Leaflet = withLoading(LeafletMaps);
// const ReactSimple = withLoading(ReactSimpleMaps);
// const Pigeons = withLoading(PigeonsMaps);
/*-----------------*/

// Error pages
const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const Notifications = withLoading(NotificationsPage);
const Payments = withLoading(PaymentsPage);

const LogoutFallback = withLoading(Logout);

const protectedLayout = (
  <RequireAuth>
    <ProtectedRoute />
  </RequireAuth>
);

// Loader for route `/auth`
export const authLoader = async ({ request }: LoaderFunctionArgs) => {
  const isLoggedIn = readToken();
  const url = new URL(request.url);

  // Check if path is "/auth" then redirect to "/auth/login"
  if ((url.pathname === '/auth' || url.pathname === '/auth/') && !isLoggedIn) {
    return redirect('/auth/login');
  }

  if (isLoggedIn) {
    return redirect('/');
  }
  return <Outlet />;
};

// Callback loader to handle TikTok authorization
const callbackLoader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const code = url.searchParams.get('code');
  const token = readToken(); // Get the token

  if (code) {
    try {
      await axios.get(`${process.env.REACT_APP_BASE_URL}/api-ex/api/v1/tiktok-auths/callback?code=${code}`, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      return redirect('/orders'); // Redirect to orders on success
    } catch (error) {
      console.error('Error during TikTok authorization:', error);
      return redirect('/orders'); // Redirect to orders on failure
    }
  }
  return redirect('/'); // Redirect to home if no code
};

export const router = createBrowserRouter([
  {
    path: '/auth',
    loader: authLoader,
    children: [
      { index: true, element: <LoginPage /> },
      { path: 'login', element: <LoginPage /> },
      { path: 'sign-up', element: <SignUpPage /> },
      { path: 'verify-account', element: <VerifyAccountPage /> },
      { path: 'verify', element: <VerifyPage /> },
      { path: 'forgot-password', element: <ForgotPasswordPage /> },
      { path: 'security-code', element: <SecurityCodePage /> },
      { path: 'new-password', element: <NewPasswordPage /> },
      { path: '*', element: <Error404 /> },
    ],
  },
  {
    path: NFT_DASHBOARD_PATH,
    element: protectedLayout,
    children: [
      { index: true, element: <NftDashboard /> },
      {
        path: 'catalog',
        children: [
          { index: true, element: <Error404 /> },
          { path: 'category', element: <Category /> },
          {
            path: 'product',
            children: [
              { index: true, element: <Product /> },
              {
                path: 'new-product',
                element: (
                  <RequireRole requiredRole={['ADMIN', 'SUPPORT']}>
                    <NewProduct />
                  </RequireRole>
                ),
              },
              {
                path: 'edit-product/:uuid',
                element: (
                  <RequireRole requiredRole={['ADMIN', 'SUPPORT']}>
                    <UpdateProduct />
                  </RequireRole>
                ),
              },
              { path: 'detail-product/:uuid', element: <ProductDetail /> },
            ],
          },
        ],
      },
      {
        path: 'orders',
        children: [
          { index: true, element: <OrderPage /> },
          { path: 'detail/:uuid', element: <OrderDetail /> },
          { path: '*', element: <Error404 /> },
        ],
      },
      { path: 'design-maker', element: <DesignMaker /> },
      { path: 'history', element: <History /> },
      { path: 'billing', element: <Billing /> },
      {
        path: 'tickets',
        children: [
          { index: true, element: <Tickets /> },
          { path: 'detail-ticket/:uuid', element: <DetailTicket /> },
          { path: '*', element: <Error404 /> },
        ],
      },
      {
        path: 'user-manager',
        element: (
          <RequireRole requiredRole={['ADMIN']}>
            <UserManager />
          </RequireRole>
        ),
      },
      {
        path: 'my-configs',
        element: (
          <RequireRole requiredRole={['ADMIN']}>
            <MyConfigsPage />
          </RequireRole>
        ),
      },
      {
        path: 'profile',
        element: <ProfileLayout />,
        children: [
          { path: 'personal-info', element: <PersonalInfo /> },
          { path: 'security-settings', element: <SecuritySettings /> },
          { path: 'notifications', element: <Notifications /> },
          { path: 'payments', element: <Payments /> },
        ],
      },
      { path: '*', element: <Error404 /> },
    ],
  },
  {
    path: '/logout',
    element: <LogoutFallback />,
  },
  {
    path: '/terms-and-conditions',
    element: <Terms />,
  },
  {
    path: '/privacy-policy',
    element: <Policy />,
  },
  { path: '*', element: <Error404 /> },
  { path: '/500', element: <ServerError /> },
  { path: '/404', element: <Error404 /> },
  {
    path: '/callback',
    loader: callbackLoader,
  },
]);

export const AppRouter: React.FC = () => {
  return <RouterProvider router={router} />;
};
