import React, { TextareaHTMLAttributes } from 'react';
import { Textarea } from './BaseTextArea.styles';

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;

function TextArea({ ...rest }: TextareaProps) {
  return <Textarea {...rest} />;
}

export default TextArea;
