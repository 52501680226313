import styled from 'styled-components';
import { BaseCard } from '../BaseCard/BaseCard';

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 1rem;
`;
export const CardItem = styled(BaseCard)`
  border: 1px solid rgb(229, 231, 235);
  border-radius: 0.5rem;
  padding-bottom: 0.75rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.5s;
  align-items: center;
  justify-content: center;
  user-select: none;
  overflow: hidden;
  &:hover {
    box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.22);
  }
  .card-avatar {
    background-color: var(--ck-image-processing-background-color);
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    img {
      object-fit: cover;
      aspect-ratio: 3/4;
      height: 100%;
      width: 100%;
      transition: transform 0.5s;
      background-color: transparent;
      box-sizing: border-box;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
  .card-text {
    padding: 0 0.5rem;
    .card-name {
      text-transform: capitalize;
      color: var(--primary-color);
      font-size: 1.25rem;
      font-weight: 700;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .card-decription {
      font-size: 0.75em;
      color: var(--primary-color);
    }
    .card-price {
      font-weight: 600;
      color: var(--ck-color-base-text);
    }
    .card-color {
      margin-top: 0.5rem;
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      span {
        height: 1rem;
        width: 1rem;
        background-color: var(--color);
        border-radius: 50%;
        border: 1px solid var(--border-nft-color);
      }
    }
  }
`;
