import { BORDER_RADIUS } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const FeesEditContainer = styled.div`
  border-radius: ${BORDER_RADIUS};
  box-shadow: var(--box-shadow-nft-color);
  width: 100%;
  padding: 1rem;
  padding-top: 1.5rem;
`;
