import styled, { keyframes } from 'styled-components';

// Pulse Animation
const pulse = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(110, 142, 251, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(110, 142, 251, 0);
  }
  100% {
     box-shadow: 0 0 0 0 rgba(110, 142, 251, 0);
  }
`;

// Ripple Animation
const ripple = keyframes`
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
`;

export const LiveChatStyle = styled.div`
  .live-chat-container {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 100;
    display: inline-block;

    &:hover .tooltip-content {
      opacity: 1;
      visibility: visible;
    }
  }

  .live-chat-icon {
    width: 50px;
    height: 50px;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    animation: ${pulse} 3s infinite;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: var(--white);
      }

      transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: rgba(255, 255, 255, 0.3);
      border-radius: inherit;
      transform: scale(0);
      opacity: 0;
    }

    &:active::before {
      animation: ${ripple} 0.6s linear;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);

      svg {
        transform: scale(1.2);
      }
    }
  }

  .tooltip-content {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    z-index: 110;
    font-size: 12px;
    width: max-content;
    background: linear-gradient(135deg, #6e8efb, #a777e3);
    color: var(--white);
  }
`;
