import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';
import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { ColumnsType } from 'antd/es/table';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { Pagination } from '@app/api/table.api';
import {
  ColorType,
  ResProduct,
  dataType,
  getProduct,
  minUnitPrice,
  paramsType,
  updateBatchStatus,
} from '@app/api/product.api';
import { AvatarTable, ColorPreview, UlSize } from '@app/components/catalog/conmon.styles';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { useResponsive } from '@app/hooks/useResponsive';
import BaseFilter, { checkedListsType } from '@app/components/common/BaseFilter/Filter';
import { Key } from 'rc-table/lib/interface';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { Row } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import useCurrentRole from '@app/hooks/useCurrentRole';
import { Card } from './Product.styles';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};

type ProductRow = {
  uuid: string;
  color: ColorType[];
  categoryName: string;
} & dataType;

const createColumns = (valueSearch: string, state: string): ColumnsType<ProductRow> => [
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    render: (text: string) => {
      return <AvatarTable alt={text} src={text} />;
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text: string, record: ProductRow) => {
      const regex = new RegExp(`(${valueSearch})`, 'gi');
      const parts = text.split(regex);

      return (
        <Link to={`detail-product/${record.uuid}?${state}`}>
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {part.toLowerCase() === valueSearch.toLowerCase() ? <strong>{part}</strong> : part}
            </React.Fragment>
          ))}
        </Link>
      );
    },
  },
  {
    title: 'Product Sku',
    dataIndex: 'productSku',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (text: string) => {
      return text.toLowerCase().replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
    },
  },
  {
    title: 'Category',
    dataIndex: 'categoryName',
  },
  {
    title: 'Unit Price',
    align: 'center',
    render: (text: string, record: ProductRow) => {
      return `$${minUnitPrice(record.unitPrices)}`;
    },
  },
  {
    title: 'Colors',
    dataIndex: 'color',
    render: (text: string, record: ProductRow) => {
      return (
        <ColorPreview>
          {record.color.map((item) => (
            <span title={item.name} key={item.hex} style={{ '--color': item.hex } as React.CSSProperties}></span>
          ))}
        </ColorPreview>
      );
    },
  },
  {
    title: 'Sizes',
    render: (text: string, record: ProductRow) => {
      return (
        <UlSize>{record.unitPrices && record.unitPrices.map((item) => <li key={item.size}>{item.size}</li>)}</UlSize>
      );
    },
  },
];
const stateToString = (paging: Pagination, s: string): string => {
  return `page=${paging.current}&size=${paging.pageSize}&s=${s}`;
};

const AdminProductPage: React.FC = () => {
  const { t } = useTranslation();
  const { isTablet } = useResponsive();
  const [valueSearch, setValueSearch] = useState<string>('');
  const [searchIpt, setSearchIpt] = useState<string>('');
  const [tableData, setTableData] = useState<{ data: ResProduct[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [filter, setFilter] = useState<checkedListsType>({});
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const wasRedirected = useRef(false);
  const [isRedirect, setIsRedirect] = useState<boolean>(true);
  const navigate = useNavigate();
  const [role, setRole] = useState<boolean>(false);
  const [columnsMr, setColumnsMr] = useState<ColumnsType<ProductRow>>(
    createColumns(valueSearch, stateToString(initialPagination, valueSearch)),
  );
  const [productsSelected, setProductsSelected] = useState<ProductRow[]>([]);
  const [isProductsSelected, setIsProductsSelected] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<string>();

  const { isAdmin, isSupport } = useCurrentRole();

  const fetch = useCallback(
    async (pagination: Pagination) => {
      let params: paramsType = {
        sort: 'createdDate,asc',
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || 10,
      };
      if (valueSearch) {
        params = {
          ...params,
          s: valueSearch,
        };
      }
      if (filter) {
        params = {
          ...params,
          ...filter,
        };
      }
      setTableData((tableData) => ({ ...tableData, loading: true }));
      try {
        const res = await getProduct(params);
        setTableData((tableData) => ({
          ...tableData,
          loading: false,
          data: res.content.map((item: ResProduct, index: number) => {
            const newItem = {
              ...item,
              no: res.pageable.pageNumber * res.size + index + 1,
              key: item.uuid,
            };
            return newItem;
          }),
          pagination: {
            total: res.totalElements,
            current: res.pageable.pageNumber + 1,
            pageSize: params.size,
          },
        }));
      } catch (err) {
        console.log(err);
      }
    },
    [valueSearch, filter],
  );
  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };
  useEffect(() => {
    wasRedirected.current = true;
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const s = queryParams.get('s');
    if (wasRedirected.current) {
      if (s && isRedirect) {
        setValueSearch(s);
        setSearchIpt(s);
        setIsRedirect(false);
      }
      const page = queryParams.get('page') || '1';
      const size = queryParams.get('size') || '10';
      const pagination: Pagination = {
        current: parseInt(page),
        pageSize: parseInt(size),
      };
      fetch(pagination);
    } else {
      fetch(initialPagination);
    }
  }, [fetch, isRedirect]);

  useEffect(() => {
    const title = t('tables.actions');
    const titleIsInColumns = columnsMr.find((item) => item.title === title);

    if ((isAdmin || isSupport) && titleIsInColumns === undefined) {
      setColumnsMr((columnsMr) => [
        ...columnsMr,
        {
          title: title,
          dataIndex: 'actions',
          align: 'center',
          render: (text: string, record: ProductRow) => {
            return (
              <BaseSpace style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                <EditIcon
                  style={{ height: '1.5rem' }}
                  onClick={() => {
                    navigate(`edit-product/${record.uuid}`);
                  }}
                />
              </BaseSpace>
            );
          },
        },
      ]);
      setRole(true);
    }
  }, [t, navigate, columnsMr, isAdmin, isSupport]);
  useEffect(() => {
    setColumnsMr(createColumns(valueSearch, stateToString(tableData.pagination, valueSearch)));
  }, [valueSearch, tableData.pagination]);

  const handleSearch = (value?: string) => {
    if (value === '') {
      setValueSearch('');
    } else {
      setValueSearch(searchIpt);
    }
  };
  const handleFilter = (options: checkedListsType) => {
    const newData = Object.entries(options).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key === 'Best Seller' ? 'isRecommend' : key.toLowerCase()]:
          key === 'Best Seller' ? value[0] : value[0].toUpperCase(),
      };
    }, {});
    setFilter(newData);
    setFilterOpen(false);
  };
  const handleResetFilter = () => {
    setFilter({});
    setFilterOpen(false);
  };
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: ProductRow[]) => {
      setProductsSelected(selectedRows);
      setIsProductsSelected(selectedRows.length > 0);
    },
    onSelect: (record: ProductRow, selected: boolean, selectedRows: ProductRow[]) => {
      console.log(record, selected, selectedRows);
    },
    onSelectAll: (selected: boolean, selectedRows: ProductRow[]) => {
      console.log(selected, selectedRows);
    },
  };

  const tabletLayout = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1.25rem',
          flexWrap: 'wrap',
        }}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
          <NewsStyle.InputWrapper>
            <NewsStyle.SearchIcon onClick={() => handleSearch()} />
            <NewsStyle.Input
              placeholder={t('header.search')}
              value={searchIpt}
              onChange={(event) => {
                const value = event.target.value;
                if (value === '') {
                  handleSearch('');
                }
                setSearchIpt(value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </NewsStyle.InputWrapper>
          <BaseFilter
            options={[
              { label: 'Best Seller', values: ['true', 'false'] },
              { label: 'Status', values: ['Draft', 'Publish', 'Disable'] },
            ]}
            open={filterOpen}
            onOpenChange={() => setFilterOpen(!filterOpen)}
            isMultipleSelect={false}
            onOk={handleFilter}
            onReset={handleResetFilter}
          />
        </div>
        {role && (
          <Row style={{ gap: '0.5rem' }}>
            {isProductsSelected && (
              <Row style={{ gap: '0.5rem' }}>
                <BaseSelect
                  placeholder="Actions"
                  width={190}
                  onChange={(value) => {
                    if (value != 'CLONE') {
                      // Thực hiện update status
                      setNewStatus(value as string);
                    } else {
                      navigate(`/catalog/product/new-product?cloneProduct=${productsSelected[0].uuid}`);
                    }
                  }}
                >
                  <Option value="DRAFT">Status is Draft</Option>
                  <Option value="PUBLISH">Status is Publish</Option>
                  <Option value="DISABLE">Status is Disable</Option>
                  {productsSelected.length === 1 && <Option value="CLONE">Clone this product</Option>}
                </BaseSelect>
                <BaseButton
                  type="ghost"
                  onClick={async () => {
                    const uuids: string[] = productsSelected.map((item: ProductRow) => item.uuid);
                    if (newStatus != undefined) {
                      await updateBatchStatus(newStatus, uuids);
                      notificationController.success({
                        message: 'Update Product!',
                        description: 'Product has successfully updated.',
                      });
                      fetch(tableData.pagination);
                    }
                  }}
                >
                  <span>Apply</span>
                </BaseButton>
              </Row>
            )}
            <BaseButton type="primary" onClick={() => navigate('new-product')}>
              <PlusIcon style={{ height: '16px' }} />
              <span>New Product</span>
            </BaseButton>
          </Row>
        )}
      </div>
    </>
  );

  const mobileLayout = (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '1.25rem',
          flexWrap: 'wrap',
          gap: '1rem',
        }}
      >
        {role && (
          <BaseButton type="primary" onClick={() => navigate('/catalog/product/new-product')}>
            <PlusIcon style={{ height: '1.25rem' }} />
            <span>New Product</span>
          </BaseButton>
        )}
        <NewsStyle.InputWrapper style={{ marginBottom: '0' }}>
          <NewsStyle.SearchIcon onClick={() => handleSearch()} />
          <NewsStyle.Input
            placeholder={t('header.search')}
            value={searchIpt}
            onChange={(event) => {
              const value = event.target.value;
              if (value === '') {
                handleSearch('');
              }
              setSearchIpt(value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </NewsStyle.InputWrapper>
        <BaseFilter
          options={[
            // { label: 'Category', values: category },
            { label: 'Status', values: ['DRAFT', 'PUBLISH', 'DISABLE'] },
          ]}
          open={filterOpen}
          onOpenChange={() => setFilterOpen(!filterOpen)}
          isMultipleSelect={false}
          onOk={handleFilter}
          onReset={handleResetFilter}
        />
      </div>
    </>
  );

  return (
    <>
      <PageTitle>{t('common.product')}</PageTitle>
      <Card id="product-card-1" padding="0" autoHeight={false}>
        {isTablet ? tabletLayout : mobileLayout}
        <BaseTable
          columns={columnsMr}
          dataSource={tableData.data}
          rowSelection={{ ...rowSelection }}
          pagination={tableData.pagination}
          loading={tableData.loading}
          onChange={handleTableChange}
          scroll={{ x: 800 }}
          rowKey={(record) => record.uuid}
        />
      </Card>
    </>
  );
};

export default AdminProductPage;
