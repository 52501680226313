import { createAction, createAsyncThunk, createSelector, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel, UserInfoModel } from '@app/domain/UserModel';
import { persistUserInfo, readToken } from '@app/services/localStorage.service';
import { UserManagerData, UserState } from '@app/types/user';
import {
  BalanceUser,
  changeBalanceUser,
  changePasswordUser,
  changeStatusUser,
  createUser,
  getUserDetail,
  getUserManager,
  PasswordUser,
  StatusUser,
  TCreateUserForm,
  TFormData,
} from '@app/api/user.api';
import { RootState } from '../store';
import { initialFormData } from '@app/pages/UserManager';

export const searchUserManager = createAsyncThunk(
  'userManager/post',
  async (formData: TFormData, { rejectWithValue }) => {
    try {
      const response = await getUserManager(formData);
      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeUserStatus = createAsyncThunk(
  'userManager/statusUser',
  async (formData: StatusUser, { rejectWithValue, dispatch }) => {
    try {
      const response = await changeStatusUser(formData);

      dispatch(searchUserManager(initialFormData));
      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updatePasswordUser = createAsyncThunk(
  'userManager/passwordUser',
  async (formData: PasswordUser, { rejectWithValue }) => {
    try {
      const response = await changePasswordUser(formData);

      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const updateBalanceUser = createAsyncThunk(
  'userManager/passwordUser',
  async (formData: BalanceUser, { rejectWithValue, dispatch }) => {
    try {
      const response = await changeBalanceUser(formData);
      dispatch(searchUserManager(initialFormData));

      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const fetchUserDetail = createAsyncThunk('userManager/userDetail', async (id: string, { rejectWithValue }) => {
  try {
    const response = await getUserDetail(id);

    return response?.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const createNewUser = createAsyncThunk(
  'userManager/newUser',
  async (formData: TCreateUserForm, { rejectWithValue, dispatch }) => {
    try {
      const response = await createUser(formData);
      dispatch(searchUserManager(initialFormData));

      return response?.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const initialUserManagerData: UserManagerData = {
  content: [],
  pageable: {
    pageNumber: 0,
    pageSize: 20,
    sort: [],
    offset: 0,
    paged: true,
    unpaged: false,
  },
  totalElements: 0,
  totalPages: 0,
  last: false,
  size: 0,
  number: 0,
  sort: [],
  numberOfElements: 0,
  first: true,
  empty: true,
};

const token = readToken();

export const initialState: UserState = {
  user: {
    uuid: '',
    avatar: '',
    fullName: '',
    email: '',
    roles: '',
    stores: [],
    lang: 'en',
    walletBalance: 0,
    socials: {
      twitter: '',
      linkedin: '',
      facebook: '',
    },
    address: {
      country: '',
      city: '',
      address1: '',
      address2: '',
      zipcode: '',
    },
  },
  userManager: {
    data: initialUserManagerData,
    loading: false,
    loadingStatus: false,
    loadingPassword: false,
    loadingBalance: false,
    dataDetail: null,
    loadingDetail: false,
  },
  isLoggedIn: !!token,
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser: UserModel) => {
  return {
    payload: newUser,
  };
});

export const setUserInfo = createAction<PrepareAction<UserInfoModel>>('user/setUserInfo', (newUserInfo) => {
  persistUserInfo(newUserInfo);

  return {
    payload: newUserInfo,
  };
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
    builder
      .addCase(searchUserManager.pending, (state) => {
        state.userManager.loading = true;
      })
      .addCase(searchUserManager.fulfilled, (state, action) => {
        state.userManager.data = action.payload;
        state.userManager.loading = false;
      })
      .addCase(searchUserManager.rejected, (state) => {
        state.userManager.loading = false;
      });
    builder
      .addCase(changeUserStatus.pending, (state) => {
        state.userManager.loadingStatus = true;
      })
      .addCase(changeUserStatus.fulfilled, (state) => {
        state.userManager.loadingStatus = false;
      })
      .addCase(changeUserStatus.rejected, (state) => {
        state.userManager.loadingStatus = false;
      });
    builder
      .addCase(updatePasswordUser.pending, (state) => {
        state.userManager.loadingPassword = true;
      })
      .addCase(updatePasswordUser.fulfilled, (state) => {
        state.userManager.loadingPassword = false;
      })
      .addCase(updatePasswordUser.rejected, (state) => {
        state.userManager.loadingPassword = false;
      });
    builder
      .addCase(fetchUserDetail.pending, (state) => {
        state.userManager.loadingDetail = true;
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.userManager.loadingDetail = false;
        state.userManager.dataDetail = action.payload;
      })
      .addCase(fetchUserDetail.rejected, (state) => {
        state.userManager.loadingDetail = false;
      });
  },
});

const { actions, reducer } = userSlice;

const userData = (state: RootState) => state.user;

export const userDataSelector = createSelector([userData], (state) => state);

export default reducer;
