import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import AuthLayout from '@app/components/layouts/AuthLayout/AuthLayout';

const SignUpPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <>
        <PageTitle>{t('common.signUp')}</PageTitle>
        <SignUpForm />
      </>
    </AuthLayout>
  );
};

export default SignUpPage;
