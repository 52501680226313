import styled, { css } from 'styled-components';
import { FONT_SIZE, FONT_WEIGHT, LAYOUT, media } from '@app/styles/themes/constants';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';

export const RightSideCol = styled(BaseCol)`
  padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${LAYOUT.desktop.headerHeight});
  background-color: var(--layout-body-bg-color);
  overflow-y: auto;
  gap: 1rem;
`;

export const LeftSideCol = styled(BaseCol)`
  @media only screen and ${media.xl} {
    padding: ${LAYOUT.desktop.paddingVertical} ${LAYOUT.desktop.paddingHorizontal};
    height: calc(100vh - ${LAYOUT.desktop.headerHeight});
    overflow: auto;
  }
`;

export const WrapperCardsStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* background-color: var(--layout-content-bg-color); */
  border-radius: 8px;
  margin: 24px 0;
`;

export const CardsTitleStyle = styled.h4<{ $fontSize?: string }>`
  font-weight: ${FONT_WEIGHT.bold};
  ${({ $fontSize }) => $fontSize && { fontSize: $fontSize ?? '16px' }};
  color: var(--primary-color);

  // TODO: change for future
  /* font-size: ${FONT_SIZE.xs};
  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.lg};
  } */
`;

export const Space = styled.div`
  margin: 1rem 0;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 250px;

  .ant-card-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 15px;

  background: black;

  min-height: 300px;
  overflow-y: auto;
`;

export const Item = styled.div`
  background: red;
  height: 150px;
  flex-shrink: 0;
`;

export const WrapperFilter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  background-color: var(--layout-content-bg-color);

  padding: 24px;
  border-radius: 8px;
`;

export const ViewIconStyle = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: var(--primary-color);
  margin-bottom: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--primary2-color);

    & svg > path:first-of-type {
      stroke: var(--primary2-color);
    }
  }

  & svg > path:first-of-type {
    stroke: var(--primary-color);
    transition: all 0.2s ease-in-out;

    &:hover {
      stroke: var(--primary2-color);
    }
  }
`;

export const SelectStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
`;

export const WrapperCardItem = styled.div`
  background-color: var(--layout-content-bg-color);
  padding: 24px;
  border-radius: 8px;
`;

export const WrapperDashboardStyle = styled.div<{ $isTablet?: boolean }>`
  ${(props) =>
    props?.$isTablet &&
    css`
      max-width: 1024px;
      margin: 0 auto;
    `}
`;
