import styled from 'styled-components';
import { FONT_SIZE, LAYOUT, media } from '@app/styles/themes/constants';
import { BaseMenu } from '@app/components/common/BaseMenu/BaseMenu';

export const Menu = styled(BaseMenu)`
  background: transparent;
  border-right: 0;

  overflow-x: hidden;
  overflow-y: auto;

  .ant-menu-item[title='Tickets'] {
    margin-top: 50px;
  }

  min-height: calc(100vh - ${LAYOUT.mobile.headerHeight} - 137px);
  max-height: calc(100vh - ${LAYOUT.mobile.headerHeight} - 137px);

  @media only screen and ${media.xl} {
    min-height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 58px);
    max-height: calc(100vh - ${LAYOUT.desktop.headerHeight} - 58px);
  }

  a {
    width: 100%;
    display: block;
  }

  .ant-menu-item-divider {
    position: relative;
    height: 50px;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .ant-menu-item,
  .ant-menu-submenu-arrow,
  .ant-menu-submenu {
    font-size: ${FONT_SIZE.md};
  }

  .ant-menu-item-icon {
    width: 1.25rem;
  }

  .ant-menu-item:hover,
  .ant-menu-submenu-title:hover {
    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    a,
    .ant-menu-item-icon,
    .ant-menu-title-content {
      color: var(--text-sider-primary-color);
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: var(--text-sider-primary-color);

      .ant-menu-submenu-expand-icon,
      .ant-menu-submenu-arrow,
      span[role='img'] {
        color: var(--text-sider-primary-color);
        fill: var(--text-sider-primary-color);
      }
    }
  }

  .ant-menu-item-selected {
    &::after {
      border-right: 5px solid var(--text-sider-primary-color);
    }

    background-color: transparent !important;
    background-color: var(--item-hover-bg) !important;

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'],
    .ant-menu-item-icon,
    a {
      color: var(--text-sider-primary-color);
    }
  }

  // TODO: confirm
  .ant-menu-submenu-open {
    .ant-menu-item-selected {
      background-color: var(--sider-child-background-color) !important;
      background-color: var(--item-hover-bg) !important;
    }
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active .ant-menu-submenu-title {
    background-color: var(--item-hover-bg);
  }

  ul.ant-menu-sub {
    background-color: transparent;
  }
`;
