import { UploadOutlined } from '@ant-design/icons';
import { httpApi } from '@app/api/http.api';
import { TypeSelect, dataType, newTicket, selectType } from '@app/api/ticket.api';
import { ModalFooter } from '@app/components/catalog/conmon.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseUpload } from '@app/components/common/BaseUpload/BaseUpload';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseInput } from '@app/components/common/inputs/BaseInput/BaseInput';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { notificationController } from '@app/controllers/notificationController';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TicketModalProps {
  isOpen: boolean;
  onOpenChange: () => void;
  onLoad: () => void;
}
const initDataModal: dataType = {
  subject: '',
  type: 0,
  content: '',
};
export const TicketModal: React.FC<TicketModalProps> = ({ isOpen, onOpenChange, onLoad }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const uploadProps = {
    name: 'file',
    height: 100,
    accept: 'image/*',
    beforeUpload: () => {
      return false;
    },
  };
  const handleUploadAttachments = async (listImages: any) => {
    if (listImages) {
      const uploadPromises = listImages.fileList.map((fileInfo: { originFileObj: File }) => {
        if (typeof fileInfo.originFileObj === 'string') {
          return fileInfo.originFileObj;
        }
        return new Promise(async (resolve) => {
          const formData = new FormData();
          formData.append('image', fileInfo.originFileObj);
          try {
            const response = await httpApi.post(`/api/v1/upload/image`, formData);
            resolve(response.data);
          } catch (error) {
            console.error('Error uploading images:', error);
            notificationController.error({ message: `Upload image ${fileInfo.originFileObj.name} failed!` });
            throw error;
          }
        });
      });
      const imageUrls = await Promise.all(uploadPromises);
      return imageUrls;
    } else {
      return [];
    }
  };
  const handleSubmit = async (values: dataType) => {
    setLoading(true);
    try {
      console.log('values', values);
      const attachments = await handleUploadAttachments(values.attachments);
      const postData = {
        ...values,
        attachments: attachments,
      };
      await newTicket(postData);
      onOpenChange();
      onLoad();
      notificationController.success({ message: 'Create new ticket success!' });
    } catch (err: any) {
      console.log('err', err);
      notificationController.error({ message: err.message });
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseModal title={`New Ticket`} width={600} open={isOpen} onCancel={onOpenChange} footer={null} destroyOnClose>
      <BaseSpin spinning={loading}>
        <div>
          <div>
            <BaseForm
              className="newTicket-modal"
              id={'ticket-form'}
              layout="vertical"
              onFinish={handleSubmit}
              initialValues={initDataModal}
            >
              <BaseRow gutter={[20, 24]}>
                <BaseCol span={18}>
                  <BaseForm.Item
                    name="subject"
                    label={'Subject'}
                    rules={[{ required: true, message: 'Subject is required' }]}
                  >
                    <BaseInput maxLength={255} />
                  </BaseForm.Item>
                </BaseCol>
                <BaseCol span={6}>
                  <BaseForm.Item name="type" label={'Type'} rules={[{ required: true, message: 'Type is required' }]}>
                    <BaseSelect height={36} width={120}>
                      {TypeSelect.map((item: selectType) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                    </BaseSelect>
                  </BaseForm.Item>
                </BaseCol>
                <BaseCol span={24}>
                  <BaseForm.Item
                    name="content"
                    label={'Content'}
                    rules={[{ required: true, message: 'Content is required' }]}
                  >
                    <TextArea style={{ minHeight: '10rem' }} maxLength={900} />
                  </BaseForm.Item>
                </BaseCol>
                <BaseCol span={24}>
                  <BaseForm.Item name="attachments" label={'Attachments'}>
                    <BaseUpload {...uploadProps}>
                      <BaseButton icon={<UploadOutlined />}>{t('uploads.clickToUpload')}</BaseButton>
                    </BaseUpload>
                  </BaseForm.Item>
                </BaseCol>
              </BaseRow>
            </BaseForm>
          </div>
          <ModalFooter>
            <div style={{ display: 'flex', gap: '1rem' }}>
              <BaseButton type="ghost" onClick={onOpenChange}>
                Cancel
              </BaseButton>
              <BaseButton type="primary" htmlType="submit" form={'ticket-form'}>
                OK
              </BaseButton>
            </div>
          </ModalFooter>
        </div>
      </BaseSpin>
    </BaseModal>
  );
};
