import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NFTCard } from '@app/components/nft-dashboard/common/NFTCard/NFTCard';
import { TopUpBalanceModal } from './components/TopUpBalanceModal/TopUpBalanceModal';
import { TopUpBalanceButton } from './components/TopUpBalanceButton/TopUpBalanceButton';
import { formatNumberWithCommas, getCurrencyPrice, TEXT_TOOLTIP_CARD_ITEM } from '@app/utils/utils';
import { Balance as IBalance } from '@app/api/earnings.api';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import { TopUpFormData } from './interfaces/interfaces';
import * as S from './Balance.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { topup } from '@app/api/topUp.api';
import { notificationController } from '@app/controllers/notificationController';
import { OverviewUser, getUser } from '@app/api/user.api';
import { Tooltip } from 'antd';

export const Balance: React.FC = () => {
  const [balance, setBalance] = useState<IBalance>({
    USD: 0,
    ETH: 0,
    BTC: 0,
  });

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    getUser().then((user) => {
      setBalance((prev) => ({
        ...prev,
        USD: user?.walletBalance || 0,
      }));
    });
  }, []);

  const { t } = useTranslation();

  const handleModal = () => setModalOpen((open) => !open);

  const onFinish = (values: TopUpFormData) => {
    setLoading(true);
    topup({
      amount: values.amount,
      evdUrl: values.evidence.file.response.data,
      paymentChannel: values.paymentChannel,
      transactionId: values.transactionId,
    })
      .then(() => {
        setTimeout(() => {
          notificationController.success({ message: t('topup.success') });
          setModalOpen(false);
        }, 1000);
      })
      .finally(() => setLoading(false));
  };

  return (
    <BaseRow>
      <BaseCol span={24}>
        <S.TitleText>{t('nft.yourBalance')}</S.TitleText>
      </BaseCol>

      <BaseCol span={24}>
        <NFTCard isSider>
          <BaseRow gutter={[30, 30]}>
            <BaseCol span={24}>
              <BaseRow gutter={[14, 14]}>
                <BaseCol span={24}>
                  <S.TitleBalanceText>
                    {getCurrencyPrice(formatNumberWithCommas(balance.USD), CurrencyTypeEnum['USD'])}
                  </S.TitleBalanceText>
                </BaseCol>

                {/* <BaseCol span={24}>
                  <BaseRow gutter={[55, 10]} wrap={false}>
                    <BaseCol>
                      <S.SubtitleBalanceText>
                        {getCurrencyPrice(formatNumberWithCommas(balance.ETH), CurrencyTypeEnum['ETH'])}
                      </S.SubtitleBalanceText>
                    </BaseCol>

                    <BaseCol>
                      <S.SubtitleBalanceText>
                        {getCurrencyPrice(formatNumberWithCommas(balance.BTC), CurrencyTypeEnum['BTC'])}
                      </S.SubtitleBalanceText>
                    </BaseCol>
                  </BaseRow>
                </BaseCol> */}
              </BaseRow>
            </BaseCol>

            <BaseCol span={24}>
              <TopUpBalanceButton onClick={handleModal} />

              {isModalOpen && <TopUpBalanceModal loading={loading} isOpen onCancel={handleModal} onFinish={onFinish} />}
            </BaseCol>
          </BaseRow>
        </NFTCard>
      </BaseCol>
    </BaseRow>
  );
};

export type Role = 'admin' | 'seller' | 'support' | 'factory';

type GRClusterProps = {
  title: keyof typeof TEXT_TOOLTIP_CARD_ITEM;
  dataShow: string | number;
  grow?: string;
  reduce?: string;
  isFlexRow?: boolean;
  role?: Role;
};

export const BaseGRCluster: React.FC<GRClusterProps> = ({
  title,
  dataShow,
  grow,
  reduce,
  isFlexRow,
  role = 'admin',
}) => {
  const titleTooltip = TEXT_TOOLTIP_CARD_ITEM[title][role];

  return (
    <BaseRow>
      <BaseCol span={24}>
        <NFTCard isSider style={{ boxShadow: 'none', border: '1px solid #e0e0e0' }}>
          <BaseRow gutter={[30, 30]}>
            <BaseCol span={24}>
              <BaseRow gutter={[14, 14]}>
                <BaseCol span={24}>
                  <S.WrapperTextStyle $isFlexRow={isFlexRow}>
                    <S.TitleText>{title}</S.TitleText>
                    <S.TitleBalanceText>{dataShow}</S.TitleBalanceText>
                  </S.WrapperTextStyle>
                </BaseCol>

                {(grow || reduce) && <BaseCol span={24}></BaseCol>}
              </BaseRow>
            </BaseCol>
            {(grow || reduce) && (
              <BaseCol span={24}>
                <S.CardFooter>
                  <div className="grow">{grow}</div>
                  <div className="reduce">{reduce}</div>
                </S.CardFooter>
              </BaseCol>
            )}
          </BaseRow>

          <Tooltip placement="topRight" title={titleTooltip} arrowPointAtCenter>
            <S.InfomationIconStyle />
          </Tooltip>
        </NFTCard>
      </BaseCol>
    </BaseRow>
  );
};

export interface DashboardPageProps {
  userOverview: OverviewUser;
  walletBalance?: number;
}
export const Customers: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return <BaseGRCluster title="Customers" dataShow={userOverview.customers} />;
};
export const Orders: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return <BaseGRCluster title="Orders" dataShow={userOverview.orders} />;
};
export const BalanceAdmin: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return (
    <BaseGRCluster
      title="Total Balance"
      dataShow={getCurrencyPrice(formatNumberWithCommas(userOverview.balance), CurrencyTypeEnum['USD'])}
    />
  );
};

export const Refunds: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return (
    <BaseGRCluster
      title="Refunds"
      dataShow={getCurrencyPrice(formatNumberWithCommas(userOverview.refunds), CurrencyTypeEnum['USD'])}
    />
  );
};

export const Pending: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return <BaseGRCluster title="Orders Pending" dataShow={userOverview.pending} />;
};

export const UnsolvedTickets: React.FC<DashboardPageProps> = ({ userOverview }) => {
  return <BaseGRCluster title="Unsolved Tickets" dataShow={userOverview.unsolvedTickets} />;
};

export const ToBePaidAmount: React.FC<DashboardPageProps> = ({ userOverview, walletBalance }) => {
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    if (walletBalance) {
      setAmount(walletBalance > userOverview.tobePaidAmount ? 0 : userOverview.tobePaidAmount - walletBalance);
    } else {
      setAmount(userOverview.tobePaidAmount);
    }
  }, [userOverview, walletBalance]);

  return (
    <BaseGRCluster
      title="Awaiting Payment"
      dataShow={getCurrencyPrice(formatNumberWithCommas(amount), CurrencyTypeEnum['USD'])}
    />
  );
};
