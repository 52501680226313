import styled, { CSSProperties } from 'styled-components';

export const WapperStyle = styled.div<{ $styles?: CSSProperties }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: #fff;

  ${({ $styles }) => $styles && { ...$styles }}; // Apply the custom styles
`;
