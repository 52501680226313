/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TableProps } from 'antd';
import * as S from './BaseTable.styles';

type BaseTable = {
  isHoverRow?: boolean;
};

export type BaseTableProps<T> = TableProps<T> & BaseTable;

// TODO make generic!
export const BaseTable: React.FC<BaseTableProps<any>> = ({ isHoverRow, ...props }) => {
  return <S.Table {...props} $isHoverRow={isHoverRow} />;
};
