import { BaseTabs } from '@app/components/common/BaseTabs/BaseTabs';
import { Modal, TabsProps } from 'antd';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Tab4 from './Tab4';

type TChartsModal = {
  onCancel: () => void;
};

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Tab 1',
    children: <Tab1 />,
  },
  {
    key: '2',
    label: 'Tab 2',
    children: <Tab2 />,
  },
  {
    key: '3',
    label: 'Tab 3',
    children: <Tab3 />,
  },
  {
    key: '4',
    label: 'Tab 4',
    children: <Tab4 />,
  },
];

const ChartsModal: React.FC<TChartsModal> = ({ onCancel }) => {
  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <Modal
      open
      title="Detailed Stats"
      width={'100%'}
      onCancel={onCancel}
      footer={null}
      style={{
        top: 0,
        padding: 0,
        width: '100vw',

        maxWidth: '100vw',
      }}
      bodyStyle={{ height: 'calc(100vh - 55px)' }}
      centered={false}
    >
      {/* Nội dung của Modal */}
      <BaseTabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Modal>
  );
};

export default ChartsModal;
