import styled, { css } from 'styled-components';
import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';
import { media } from '@app/styles/themes/constants';
import { CSSProperties } from 'react';

export const MainStyle = styled(BaseLayout.Content)<{ $isNotDesktop: boolean }>`
  display: flex;
  padding-top: 80px;

  ${(props) =>
    props.$isNotDesktop &&
    css`
      padding-top: 68px;
    `}

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`;

export const MainFooterStyle = styled(BaseLayout.Footer)<{ $styles?: CSSProperties }>`
  padding: 0;
  margin-top: auto;
  ${({ $styles }) => $styles && { ...$styles }}; // Apply the custom styles
`;

export const RightMainStyle = styled.div<{ $isNotDesktop: boolean; $styles?: CSSProperties }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 40px 24px 16px;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  transition: all 0.2s ease-in-out;
  overflow-y: scroll;

  ${(props) =>
    props.$isNotDesktop &&
    css`
      padding: 16px;
      min-height: calc(100vh - 68px);
      max-height: calc(100vh - 68px);
    `}

  ${({ $styles }) => $styles && { ...$styles }}; // Apply the custom styles
`;
