import { detailOrderProduct } from '@app/api/order.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { NFTCard } from '@app/components/nft-dashboard/common/NFTCard/NFTCard';
import { TitleBalanceText, TitleText } from '@app/components/orders/Orders.styles';
import { ColumnsType } from 'antd/lib/table';

export interface TotalCardPros {
  total: number;
  shippingFees: number;
  products: detailOrderProduct[];
  hasHandlingFee: boolean;
}

export const TotalCard: React.FC<TotalCardPros> = ({
  total,
  shippingFees,
  products,
  hasHandlingFee,
}: TotalCardPros) => {
  const columnsMr: ColumnsType<any> = [
    {
      title: 'Product',
      dataIndex: 'no',
      render: (no: number, product: detailOrderProduct) => {
        return (
          <span>
            <a href={`/catalog/product/detail-product/${product.productUuid}`} target='"_blank"'>
              {no}. {product.name} - {product.productSku}
            </a>
            {product.designFee != 0 && (
              <>
                <br></br>Design fee: ${product.designFee}
              </>
            )}
          </span>
        );
      },
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      align: 'center',
      width: '87px',
      render: (quantity: number) => {
        return `${quantity}`;
      },
    },
    {
      title: 'Unit Price',
      dataIndex: 'unitPrice',
      align: 'center',
      width: '87px',
      render: (unitPrice: number) => {
        return `$${unitPrice}`;
      },
    },
    {
      title: 'Extra Fees',
      dataIndex: 'extraFee',
      align: 'center',
      width: '76px',
      render: (extraFee: number) => {
        return `$${extraFee || 0}`;
      },
    },
  ];

  const breakdown = products.map((product, index) => ({
    ...product,
    no: index + 1,
  }));

  return (
    <BaseRow>
      <BaseCol span={24}>
        <TitleText level={2}>Total</TitleText>
      </BaseCol>

      <BaseCol span={24}>
        <NFTCard isSider padding={0}>
          <BaseRow gutter={[30, 30]}>
            <BaseCol span={24}>
              <BaseRow gutter={[14, 14]}>
                <BaseCol span={24}>
                  <TitleBalanceText level={3}>
                    <BaseTable
                      columns={columnsMr}
                      dataSource={breakdown}
                      pagination={false}
                      rowKey={(record) => record.uuid}
                    />
                    {hasHandlingFee ? (
                      <h4 style={{ padding: '0.5rem 1rem 0', fontSize: '1rem', margin: '0' }}>Handling Fee: $1</h4>
                    ) : (
                      <h4 style={{ padding: '0.5rem 1rem 0', fontSize: '1rem', margin: '0' }}>
                        Shipping Fees: ${shippingFees}
                      </h4>
                    )}
                    <h4 style={{ padding: '0 1rem 8px', marginTop: '0.75rem' }}>Total: {total}</h4>
                  </TitleBalanceText>
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        </NFTCard>
      </BaseCol>
    </BaseRow>
  );
};
