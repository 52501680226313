import React from 'react';
import * as S from './TopUpPaymentCard.styles';

interface TopUpPaymentCardProps {
  img: string;
  type: string;
}

export const TopUpPaymentCard: React.FC<TopUpPaymentCardProps> = ({ img, type }) => {
  return (
    <S.Wrapper>
      <S.CardIssuerImg src={img} alt={type} />
    </S.Wrapper>
  );
};
