import { Layout } from '@app/components/terms-policy/TermsPolicy';
import React from 'react';

function TermsPage() {
  const title = 'Terms and Conditions';
  const title1 = '1. ACCEPTANCE OF TERMS';
  const title2 = '2. DESCRIPTION OF SERVICE';
  const title3 = '3. YOUR REGISTRATION OBLIGATIONS';
  const title4 = '4. EMBROIDERY GOODS PRIVACY POLICY';
  const title5 = '5. CUSTOMER ACCOUNT, PASSWORD AND SECURITY';
  const title6 = '6. SPECIAL ADMONITIONS FOR INTERNATIONAL USE';
  const title7 = '7. INDEMNITY';
  const title8 = '8. LIMITATION OF LIABILITY';
  const title9 = '9. GENERAL INFORMATION';
  const title10 = '10. VIOLATIONS';

  const terms1 = `Welcome to Embroidery Goods (the "Company"). We agree to provide service to you, subject to the following Terms of Service ("Terms"), which may be updated by us from time to time without notice to you.
  
  When using the Service (as defined below), you shall be subject to any posted guidelines and rules applicable to such Service which may be posted on the website from time to time. All such guidelines or rules are hereby incorporated by reference into the Terms.`;
  const terms2 = `Embroidery Goods currently provides users with access to a rich collection of online resources, including various communications tools, personalized content and branded programming, through the Company's website and various links to other websites (the "Service"). Unless explicitly stated otherwise, any new features that augment or enhance the current Service, including the release of any new website features, shall be subject to the Terms. You understand and agree that the Service is provided "AS-IS" and that we assume no liability in connection with the timeliness, deletion, misdelivery, or failure to store any user communications or personalization settings.
  
  In order to use the Service, you must obtain access to the World Wide Web, either directly or through devices that access web-based content, and pay any service fees associated with such access. In addition, you must provide all equipment necessary to make such a connection to the World Wide Web, including a computer and modem or other access devices.`;
  const terms3 = `In consideration of your use of the Service, you agree to: (a) provide true, accurate, current and complete information about yourself as prompted by the Service's registration form (such information being the "Registration Data") and (b) maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that is untrue, inaccurate, not current or incomplete, or we have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, we have the right to suspend or terminate your account and refuse any and all current or future use of the Service (or any portion thereof).`;
  const terms4 = `Registration Data and certain other information about you are subject to our Privacy Policy. For more information, please see our full privacy policy.`;
  const terms5 = `You will receive a password and account designation upon completing the Service's registration process. You are responsible for maintaining the confidentiality of the password and account and are fully responsible for all activities that occur in connection with your password or account. You agree to (a) immediately notify us of any unauthorized use of your password or account or any other breach of security, and (b) properly exit your account at the end of each session. We cannot and will not be liable for any loss or damage arising from your failure to comply with this Section 5.`;
  const terms6 = `Recognizing the global nature of the Internet, you agree to comply with all local rules regarding online conduct and acceptable Content. Specifically, you agree to comply with all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside.`;
  const terms7 = `You agree to indemnify and hold the Company, and its subsidiaries, affiliates, officers, agents, co-branders or other partners, and employees, harmless from any claim or demand, including reasonable attorneys' fees, whether made by or on behalf of the Company, or made by or on behalf of any third party, due to or arising out of Content you submit, post to or transmit through the Service, your use of the Service, your connection to the Service, your violation of the Terms, or your violation of any rights of another.`;
  const terms8 = `You expressly understand and agree that the company shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if we has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the service; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the service; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; or (v) any other matter relating to the service.`;
  const terms9 = `The Terms hereof constitute the entire agreement between you and the Company and govern your use of the Service, superseding any prior agreements. You also may be subject to additional terms and conditions that may apply when you use affiliate services, third-party content or third-party software. The Terms and the relationship between you and the Company shall be governed by the internal laws of the State of California without regard to any conflict of law provisions which may be applied thereby. All claims, disputes and other matters in question arising out of or relating to this Agreement, or the performance thereof by any party hereto, may be submitted by either party to, and shall be finally determined by, binding arbitration. Such arbitration shall proceed in accordance with the Commercial Arbitration Rules of the American Arbitration Association as then in effect, and conducted in the County of Los Angeles, State of California using the governing laws set forth above. The failure of the Company to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect, You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Service or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.
  
  The section titles in the Terms are for convenience only and have no legal or contractual effect.`;
  const terms10 = `Please report any violations of the Terms to us on our Contact Us page.`;

  const data = [
    { title: title1, content: terms1 },
    { title: title2, content: terms2 },
    { title: title3, content: terms3 },
    { title: title4, content: terms4 },
    { title: title5, content: terms5 },
    { title: title6, content: terms6 },
    { title: title7, content: terms7 },
    { title: title8, content: terms8 },
    { title: title9, content: terms9 },
    { title: title10, content: terms10 },
  ];
  return <Layout title={title} data={data}></Layout>;
}

export default TermsPage;
