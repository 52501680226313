import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarItems } from '../sidebarNavigation';
import useCurrentRole from '@app/hooks/useCurrentRole';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const AllowAdminPage = ['user-manager', 'my-configs'];

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const { isAdmin, isSupport } = useCurrentRole();

  const isSubMenu = isAdmin || isSupport;

  const currentMenuItem = sidebarItems({ t, isSubMenu }).find(({ key }) => location.pathname.includes(key));

  const isCatalogScreen = location.pathname.includes('catalog');

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : ['dashboard'];

  const openedSubmenu = currentMenuItem?.children?.find(({ key }) => location.pathname.includes(key));

  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  const items = useMemo(() => {
    const result = sidebarItems({ t, isSubMenu }).filter((item) =>
      isAdmin ? item : !AllowAdminPage.includes(item.key),
    );

    return result;
  }, [isAdmin, isSubMenu, t]);

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={isSubMenu && isCatalogScreen ? defaultOpenKeys : defaultSelectedKeys}
      defaultOpenKeys={isSubMenu ? defaultSelectedKeys : defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={items}
    />
  );
};

export default SiderMenu;
