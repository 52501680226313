import React from 'react';
import { LiveChatStyle } from './LiveChat.styles';
import { ReactComponent as TelegramIcon } from '@app/assets/icons/live-chat.svg';

const LiveChat = () => {
  return (
    <LiveChatStyle>
      <div className="live-chat-container">
        <div className="tooltip-content">Chat with us!</div>
        <a className="live-chat-icon" href="https://t.me/+njj9g-QX4h84Zjll" target="_blank" rel="noopener noreferrer">
          <TelegramIcon />
        </a>
      </div>
    </LiveChatStyle>
  );
};

export default LiveChat;
