import React from 'react';
import { Line } from '@ant-design/plots';

type TData = {
  year: string;
  value: number;
};

const Tab1: React.FC = () => {
  const data: TData[] = [
    { year: '1991', value: 300 },
    { year: '1992', value: 400 },
    { year: '1993', value: 350 },
    { year: '1994', value: 500 },
    { year: '1995', value: 490 },
    { year: '1996', value: 600 },
    { year: '1997', value: 700 },
    { year: '1998', value: 900 },
    { year: '1999', value: 1300 },
    { year: '2000', value: 1200 },
  ];

  const config = {
    data,
    xField: 'year',
    yField: 'value',
    // scale: {
    //   y: {
    //     domain: [0, maxValue.value],
    //   },
    // },
    point: {
      shapeField: 'circle',
      sizeField: 3,
    },
    // interaction: {
    //   tooltip: true,
    //   //   tooltip: {
    //   // render: (event, { title, items }) => <div>Your custom render content here.</div>,
    //   //   },
    // },
    style: {
      lineWidth: 2,
    },
    axis: {
      y: {
        labelFormatter: (v: string) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };

  return <Line {...config} />;
};
export default Tab1;
