import { httpApi } from './http.api';

export type ParamsMyConfig = {
  s?: string;
  size: number;
  page: number;
};

export const getMyConfig = async (params: ParamsMyConfig) => {
  const queries = { ...params, s: params.s || undefined };
  const res = await httpApi.get('api/v1/configs/search', {
    params: queries,
  });
  return res;
};

export type ParamsUpdateMyConfig = {
  key: string;
  value: string;
};

export const updateMyConfig = async (params: ParamsUpdateMyConfig) => {
  const res = await httpApi.put('api/v1/configs/update', {
    ...params,
  });
  return res;
};
