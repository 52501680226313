import { dataType, getCategory, paramsType } from '@app/api/category.api';
import { Pagination } from '@app/api/table.api';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useMounted } from '@app/hooks/useMounted';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from '@app/assets/icons/edit.svg';
import { ReactComponent as PlusIcon } from '@app/assets/icons/plus.svg';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import * as StyleTable from '@app/components/tables/Tables/Tables.styles';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { CategoryData, CategoryModal } from '@app/components/catalog/common';
import useCurrentRole from '@app/hooks/useCurrentRole';
import BaseLayout from '@app/components/layouts/BaseLayout';

const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
  total: 0,
};
const initialCateData: CategoryData = {
  uuid: '',
  name: '',
  preCode: '',
  description: '',
};
const createColumns = (valueSearch: string): ColumnsType<CategoryData> => [
  {
    title: 'No.',
    dataIndex: 'no',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text: string) => {
      const regex = new RegExp(`(${valueSearch})`, 'gi');
      const parts = text.split(regex);

      return (
        <span>
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {part.toLowerCase() === valueSearch.toLowerCase() ? <strong>{part}</strong> : part}
            </React.Fragment>
          ))}
        </span>
      );
    },
  },
  {
    title: 'Pre-code',
    dataIndex: 'preCode',
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
];

const CategoryPage: React.FC = () => {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<{ data: CategoryData[]; pagination: Pagination; loading: boolean }>({
    data: [],
    pagination: initialPagination,
    loading: false,
  });
  const [valueSearch, setValueSearch] = useState<string>('');
  const [searchIpt, setSearchIpt] = useState<string>('');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { isMounted } = useMounted();
  const [cateData, setCateData] = useState<CategoryData>(initialCateData);
  const [action, setAction] = useState<string>('New');
  const [needLoad, setNeedLoad] = useState<boolean>(true);
  const [role, setRole] = useState<boolean>(false);
  const [columnsMr, setColumnsMr] = useState<ColumnsType<CategoryData>>(createColumns(valueSearch));

  const { isSupport, isAdmin } = useCurrentRole();

  const fetch = useCallback(
    async (pagination: Pagination) => {
      let params: paramsType = {
        sort: 'createdDate,desc',
        page: (pagination.current || 1) - 1,
        size: pagination.pageSize || 10,
      };
      if (valueSearch) {
        params = {
          ...params,
          s: valueSearch,
        };
      }
      setTableData((tableData) => ({ ...tableData, loading: true }));
      try {
        const res = await getCategory(params);
        if (isMounted.current) {
          setTableData((tableData) => ({
            ...tableData,
            loading: false,
            data: res.content.map((item: dataType, index: number) => {
              const newItem = {
                ...item,
                no: res.number * res.size + index + 1,
              };
              return newItem;
            }),
            pagination: {
              total: res.totalElements,
              current: res.number + 1,
              pageSize: params.size,
            },
          }));
        }
      } catch (err) {
        console.log(err);
      }
    },
    [isMounted, valueSearch],
  );
  useEffect(() => {
    if (needLoad) {
      fetch(initialPagination);
      setNeedLoad(false);
    }
  }, [fetch, needLoad]);

  useEffect(() => {
    const title = t('tables.actions');
    const titleIsInColumns = columnsMr.find((item) => item.title === title);

    if ((isAdmin || isSupport) && titleIsInColumns === undefined) {
      setColumnsMr((columnsMr) => [
        ...columnsMr,
        {
          title: title,
          dataIndex: 'actions',
          align: 'center',
          render: (text: string, record: CategoryData) => {
            return (
              <BaseSpace style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}>
                <EditIcon
                  style={{ height: '1.5rem' }}
                  onClick={() => {
                    setModalOpen(true);
                    setCateData(record);
                    setAction('Edit');
                  }}
                />
              </BaseSpace>
            );
          },
        },
      ]);
      setRole(true);
    }
  }, [t, columnsMr, isAdmin, isSupport]);
  useEffect(() => {
    setColumnsMr(createColumns(valueSearch));
  }, [valueSearch]);

  const handleTableChange = (pagination: Pagination) => {
    fetch(pagination);
  };
  const handleModal = () => {
    setAction('New');
    setCateData(initialCateData);
    setModalOpen((open) => !open);
  };
  const handleSearch = (value?: string) => {
    if (value === '') {
      setValueSearch('');
    } else {
      setValueSearch(searchIpt);
    }
    setNeedLoad(true);
  };

  return (
    <BaseLayout>
      <>
        <PageTitle>{t('common.category')}</PageTitle>

        <StyleTable.Card id="category-card" padding="0" autoHeight={false}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '1.25rem',
              flexWrap: 'wrap',
              gap: '24px',
            }}
          >
            <NewsStyle.InputWrapper style={{ marginBottom: '0' }}>
              <NewsStyle.SearchIcon onClick={() => handleSearch()} />
              <NewsStyle.Input
                placeholder={t('header.search')}
                value={searchIpt}
                onChange={(event) => {
                  const value = event.target.value;
                  if (value === '') {
                    handleSearch('');
                  }
                  setSearchIpt(value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleSearch();
                  }
                }}
              />
            </NewsStyle.InputWrapper>

            {role && (
              <BaseButton type="primary" onClick={handleModal}>
                <PlusIcon style={{ height: '16px' }} />
                <span>New Category</span>
              </BaseButton>
            )}
          </div>

          <BaseTable
            columns={columnsMr}
            dataSource={tableData.data}
            pagination={tableData.pagination}
            loading={tableData.loading}
            onChange={handleTableChange}
            scroll={{ x: 800 }}
            rowKey={(record) => record.uuid}
          />
        </StyleTable.Card>

        {isModalOpen && (
          <CategoryModal
            data={cateData}
            type={action}
            isOpen
            onOpenChange={handleModal}
            onLoad={() => setNeedLoad(true)}
          />
        )}
      </>
    </BaseLayout>
  );
};

export default CategoryPage;
