import { detailOrder } from '@app/api/order.api';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { ModalContainer } from '../Orders.styles';
import { ModalFooter, ModalMain } from '@app/components/catalog/conmon.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import EditFeesProducts from './EditFeesProducts';

type formProps = {
  handleSubmit: (values: detailOrder) => void;
  detail: detailOrder;
  isOpen: boolean;
  onOpenChange: () => void;
};
function FormEditFees({ handleSubmit, detail, isOpen, onOpenChange }: formProps) {
  return (
    <>
      <BaseCol span={24}>
        <BaseModal
          title={`Order: ${detail.orderId} - Edit Fees`}
          width={750}
          open={isOpen}
          onCancel={onOpenChange}
          footer={null}
          destroyOnClose
        >
          <ModalContainer>
            <ModalMain>
              <BaseForm id={'detail-form'} layout="vertical" onFinish={handleSubmit}>
                <BaseCol span={24} style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                  <EditFeesProducts initData={detail} />
                </BaseCol>
              </BaseForm>
            </ModalMain>
            <ModalFooter>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <BaseButton type="ghost" onClick={onOpenChange}>
                  Cancel
                </BaseButton>
                <BaseButton type="primary" htmlType="submit" form={'detail-form'}>
                  Save
                </BaseButton>
              </div>
            </ModalFooter>
          </ModalContainer>
        </BaseModal>
      </BaseCol>
    </>
  );
}

export default FormEditFees;
