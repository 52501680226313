import React from 'react';
import * as S from './References.styles';
import { YoutubeOutlined, FacebookOutlined } from '@ant-design/icons';
import yelpIcon from '@app/assets/icons/yelp.svg';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>&copy; 2024 Egfulfill - All Rights Reserved.</S.Text>
      <S.Icons>
        <a href="https://www.youtube.com/@embroiderygoods" target="_blank" rel="noreferrer">
          <YoutubeOutlined />
        </a>
        <a href="https://www.yelp.com/biz/embroidery-goods-santa-fe-springs" target="_blank" rel="noreferrer">
          <img src={yelpIcon} width={32} style={{ padding: '1.5px' }} />
        </a>
        <a href="https://www.facebook.com/groups/245320507285128" target="_blank" rel="noreferrer">
          <FacebookOutlined />
        </a>
      </S.Icons>
    </S.ReferencesWrapper>
  );
};
