import { Key } from 'react';
import { Page, httpApi } from './http.api';

export type paramsType = {
  sort?: string;
  page: number;
  size: number;
  s?: string;
  isRecommend?: boolean;
};
export type positionsType = {
  position: string;
  type: string;
  surcharge: number;
  mockup: File[] | string;
};
export type shippingRatesType = {
  operator: string;
  quantity: number;
  surcharge: number;
};
export type ColorType = {
  hex: string;
  name: string;
};
export type UnitPriceType = {
  unitPrice: string | number;
  size: string;
};
export type dataType = {
  categoryUuid: string;
  productSku: string;
  quantity: string;
  name: string;
  status: string;
  avatar: File[] | string;
  images: File[] | string[];
  colors: ColorType[];
  unitPrices: UnitPriceType[];
  descriptions: string;
  positions: positionsType[];
  shippingRates: shippingRatesType[];
  recommend: boolean;
};

export type ResProduct = {
  uuid: string;
} & dataType;

export const minUnitPrice = (unitPrices: UnitPriceType[]): number => {
  if (unitPrices && unitPrices.length > 0) {
    return Math.min(...unitPrices.map((item) => Number(item.unitPrice)));
  }
  return 0;
};

export const getProduct = async (params: paramsType): Promise<Page<ResProduct>> => {
  const res = await httpApi.get('/api/v1/product/search', {
    params: params,
  });
  return res.data;
};
export const newProduct = async (data: dataType) => {
  const res = await httpApi.post('/api/v1/product', data);
  return res;
};
export const updateProduct = async (uuid: string, data: dataType) => {
  const res = await httpApi.put(`/api/v1/product/${uuid}`, data);
  return res;
};
export const getProductDetail = async (uuid: string) => {
  const res = await httpApi.get(`/api/v1/product/${uuid}`);
  return res.data;
};
export const updateBatchStatus = async (newStatus: string, productIds: string[]) => {
  const res = await httpApi.put(`api/v1/product/status`, {
    newStatus: newStatus,
    productIds: productIds,
  });
  return res;
};
