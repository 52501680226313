import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Input } from '../orderDetail/Detail.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { getReplaceLink, updateOrderProductPosition, updateOrderProductPositionResponse } from '@app/api/order.api';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useCallback, useState } from 'react';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { ModalContainer, ModalFooter, ModalMain } from '@app/components/catalog/conmon.styles';
import { BaseTooltip } from '@app/components/common/BaseTooltip/BaseTooltip';
import { CopyOutlined } from '@ant-design/icons';
import { PositionRowText } from '../Orders.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

type formProps = {
  handleSubmit: (values: updateOrderProductPosition) => void;
  isMockup?: boolean;
  detail?: updateOrderProductPosition;
  isOpen?: boolean;
  onOpenChange: () => void;
  replaceLink?: updateOrderProductPositionResponse;
  orderDetailUuid?: string;
};

function ModalEditMockup({
  handleSubmit,
  isMockup,
  detail,
  isOpen,
  replaceLink,
  onOpenChange,
  orderDetailUuid,
}: formProps) {
  const [loading, setLoading] = useState(false);
  const [tooltipTextMokcup, setTooltipTextMokcup] = useState<string>('Copy');

  return (
    <>
      <BaseCol span={24}>
        <BaseModal
          title={isMockup ? 'Edit Mockup' : 'Edit Design'}
          width={750}
          open={isOpen}
          onCancel={onOpenChange}
          footer={null}
          destroyOnClose
        >
          <BaseSpin spinning={loading}>
            <ModalContainer>
              <ModalMain>
                <BaseForm
                  id={'detail-form'}
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={{
                    orderUuid: detail?.orderUuid,
                    productSku: detail?.productSku,
                    mockupLink: detail?.position.mockupLink,
                    design: detail?.position.design,
                    position: detail?.position.position,
                    type: detail?.position.type,
                    colorName: detail?.color.name,
                    colorHex: detail?.color.hex,
                    size: detail?.size,
                    uuid: orderDetailUuid,
                  }}
                >
                  <BaseForm.Item label="" name="uuid" hidden>
                    <Input value={orderDetailUuid}></Input>
                  </BaseForm.Item>
                  <BaseForm.Item label="" name="orderUuid" hidden>
                    <Input value={detail?.orderUuid}></Input>
                  </BaseForm.Item>
                  <BaseForm.Item label="" name="productSku" hidden>
                    <Input value={detail?.productSku}></Input>
                  </BaseForm.Item>
                  <BaseForm.Item label="" name="position" hidden>
                    <Input value={detail?.position.position}></Input>
                  </BaseForm.Item>
                  <BaseForm.Item label="" name="type" hidden>
                    <Input value={detail?.position.type}></Input>
                  </BaseForm.Item>
                  <BaseForm.Item label="" name="colorName" hidden>
                    <Input value={detail?.color.name}></Input>
                  </BaseForm.Item>
                  <BaseForm.Item label="" name="colorHex" hidden>
                    <Input value={detail?.color.hex}></Input>
                  </BaseForm.Item>
                  <BaseForm.Item label="" name="size" hidden>
                    <Input value={detail?.size}></Input>
                  </BaseForm.Item>
                  <BaseRow>
                    {isMockup && (
                      <>
                        <BaseCol span={24}>
                          <BaseForm.Item label="Mockup" name="mockupLink">
                            <Input value={detail?.position.mockupLink} style={{ paddingRight: '3rem' }}></Input>
                          </BaseForm.Item>
                        </BaseCol>
                        <BaseForm.Item label="Design" name="design" hidden>
                          <Input value={detail?.position.design}></Input>
                        </BaseForm.Item>
                      </>
                    )}
                    {!isMockup && (
                      <>
                        <BaseForm.Item label="Mockup" name="mockupUrl" hidden>
                          <Input value={detail?.position.mockupLink}></Input>
                        </BaseForm.Item>
                        <BaseCol span={24}>
                          <BaseForm.Item label="Design" name="design">
                            <Input value={detail?.position.design} style={{ paddingRight: '3rem' }}></Input>
                          </BaseForm.Item>
                        </BaseCol>
                      </>
                    )}
                    <BaseTooltip title="Copy this link">
                      <BaseButton
                        size="small"
                        severity="success"
                        onClick={() => {
                          navigator.clipboard.writeText(detail?.position.mockupLink || '');
                        }}
                        style={{
                          position: 'absolute',
                          right: '0.5rem',
                          top: '2.625rem',
                        }}
                      >
                        <CopyOutlined style={{ lineHeight: 0 }} />
                      </BaseButton>
                    </BaseTooltip>
                  </BaseRow>
                </BaseForm>
                {((isMockup && replaceLink?.mockupUrl) || (!isMockup && replaceLink?.designUrl)) && (
                  <div style={{ padding: '0 24px' }}>
                    <p style={{ margin: '0' }}>Found a duplicate link:</p>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '1rem',
                        marginBottom: '0.5rem',
                      }}
                    >
                      <PositionRowText style={{ padding: '0.25rem 0' }}>
                        {isMockup ? replaceLink.mockupUrl : replaceLink.designUrl}
                      </PositionRowText>
                      <BaseTooltip title={tooltipTextMokcup}>
                        <BaseButton
                          size="small"
                          type="ghost"
                          onClick={() => {
                            navigator.clipboard.writeText(isMockup ? replaceLink.mockupUrl : replaceLink.designUrl);
                            setTooltipTextMokcup('Copied');
                          }}
                          onMouseLeave={() => setTooltipTextMokcup('Copy')}
                        >
                          <CopyOutlined style={{ lineHeight: 0 }} />
                        </BaseButton>
                      </BaseTooltip>
                    </div>
                  </div>
                )}
              </ModalMain>
              <ModalFooter>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <BaseButton type="ghost" onClick={onOpenChange}>
                    Cancel
                  </BaseButton>
                  <BaseButton type="primary" htmlType="submit" form={'detail-form'}>
                    Save
                  </BaseButton>
                </div>
              </ModalFooter>
            </ModalContainer>
          </BaseSpin>
        </BaseModal>
      </BaseCol>
    </>
  );
}

export default ModalEditMockup;
