import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { getMyConfig, ParamsMyConfig, updateMyConfig } from '@app/api/myConfig.api';
import { MyConfigs } from '@app/types/myConfig';
import { notificationController } from '@app/controllers/notificationController';
import { initialFormData } from '@app/pages/MyConfigs';

export const fetchMyConfigs = createAsyncThunk(
  'myConfigs/fetchMyConfigs',
  async (params: ParamsMyConfig, { rejectWithValue }) => {
    try {
      const { data } = await getMyConfig(params);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const changeMyConfigs = createAsyncThunk(
  'myConfigs/changeMyConfigs',
  async (value: string, { rejectWithValue, getState, dispatch }) => {
    const { myConfigs } = getState() as RootState;

    const params = { value, key: myConfigs.myConfigKey };
    try {
      const { data } = await updateMyConfig(params);
      dispatch(fetchMyConfigs(initialFormData));

      notificationController.success({
        message: 'Updated successfully!',
      });
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export type State = {
  myConfigs: MyConfigs;
  loading: boolean;
  myConfigKey: string;
};

export const initialState = (): State => ({
  myConfigs: null,
  loading: false,
  myConfigKey: '',
});

const myConfigsSlice = createSlice({
  name: 'myConfigs',
  initialState: initialState(),
  reducers: {
    setMyConfigKey(state, action) {
      state.myConfigKey = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyConfigs.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMyConfigs.fulfilled, (state, action) => {
        state.myConfigs = action.payload;
        state.loading = false;
      })
      .addCase(fetchMyConfigs.rejected, (state) => {
        state.loading = false;
      });
    builder
      .addCase(changeMyConfigs.pending, (state) => {
        state.loading = true;
      })
      .addCase(changeMyConfigs.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changeMyConfigs.rejected, (state) => {
        state.loading = false;
      });
  },
});

const { actions, reducer } = myConfigsSlice;

export const { setMyConfigKey } = actions;

export default reducer;

export const myConfigsData = (state: RootState) => state.myConfigs;

export const historyDataSelector = createSelector([myConfigsData], (state) => state);
