import React from 'react';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { FormInputPassword } from '@app/components/layouts/AuthLayout/AuthLayout.styles';

export type EditUserFormData = {
  amount: number;
  password: string;
  rePassword: string;
};

interface EditUserModalProps {
  title: string;
  isOpen: boolean;
  onCancel: () => void;
  onFinish: (data: EditUserFormData) => void;
  loading?: boolean;
}

export const EditUserModal: React.FC<EditUserModalProps> = ({ title, isOpen, onCancel, onFinish, loading }) => {
  const { t } = useTranslation();
  const [form] = BaseForm.useForm();

  return (
    <BaseModal title={title} width={500} open={isOpen} onCancel={onCancel} footer={null} destroyOnClose>
      <BaseForm form={form} name="editUser" onFinish={onFinish} requiredMark="optional" validateTrigger="onBlur">
        {title === 'Change Password' ? (
          <>
            <BaseForm.Item
              label={t('common.password')}
              name="password"
              rules={[
                { required: true, message: t('common.requiredField') },
                () => ({
                  validator(_, value) {
                    const textErr: string[] = [];
                    if (!value.match(/(?=.*[0-9])/)) {
                      textErr.push('At least one digit.');
                    }
                    if (!value.match(/(?=.*[a-z])/)) {
                      textErr.push('At least one lowercase letter.');
                    }
                    if (!value.match(/(?=.*[A-Z])/)) {
                      textErr.push('At least one uppercase letter.');
                    }
                    if (!value.match(/(?=.*[!#$%&?])/)) {
                      textErr.push('At least one special character from !#$%&?.');
                    }
                    if (!value.match(/^(?!.*\s).{1,}$/)) {
                      textErr.push('No whitespace allowed.');
                    }
                    if (!value.match(/.{8,}/)) {
                      textErr.push('At least 8 characters in length.');
                    }
                    if (textErr.length > 0) {
                      return Promise.reject(new Error(textErr.join(' ')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              {/* <BaseInput /> */}
              <FormInputPassword placeholder={t('common.password')} />
            </BaseForm.Item>

            <BaseForm.Item
              label={t('common.confirmPassword')}
              name="rePassword"
              dependencies={['password']}
              rules={[
                { required: true, message: t('common.requiredField') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('common.confirmPasswordError')));
                  },
                }),
              ]}
            >
              <FormInputPassword placeholder={t('common.confirmPassword')} />
            </BaseForm.Item>
          </>
        ) : (
          <BaseForm.Item name="amount">
            <InputNumber block type="number" addonBefore={<>USD</>} min={0} autoFocus />
          </BaseForm.Item>
        )}

        <BaseForm.Item>
          <BaseButton type="primary" htmlType="submit" block disabled={loading}>
            OK
          </BaseButton>
        </BaseForm.Item>
      </BaseForm>
    </BaseModal>
  );
};
