import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ForgotPasswordForm } from '@app/components/auth/ForgotPasswordForm/ForgotPasswordForm';
import AuthLayout from '@app/components/layouts/AuthLayout/AuthLayout';

const ForgotPasswordPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AuthLayout>
      <>
        <PageTitle>{t('common.forgotPass')}</PageTitle>
        <ForgotPasswordForm />
      </>
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
