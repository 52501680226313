import { FONT_SIZE } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  max-height: calc(100vh - 330px);
  min-height: calc(100vh - 330px);
  overflow-y: scroll;

  .box-message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
    padding-top: 1.25rem;
    position: relative;
  }
  .owner {
    flex-direction: row-reverse;
    margin-right: 1rem;
    padding-top: 0;
    .ant-avatar {
      margin-left: 1rem;
    }
    .anticon {
      margin-right: 1rem;
    }
    .author {
      left: auto;
      right: 3.25rem;
      display: none;
    }
  }
  .author {
    position: absolute;
    text-transform: capitalize;
    top: 0;
    left: 3.25rem;
    color: rgb(101, 103, 107);
    font-size: ${FONT_SIZE.xxs};
  }
`;

export const ReplyContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 1rem 1rem;
  gap: 1rem;

  .anticon-send {
    font-size: 1.75rem;
    color: var(--primary-color);
    cursor: pointer;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
  background-color: rgb(240, 240, 240);
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  .content {
    overflow-wrap: break-word;
  }
`;

export const BoxTime = styled.div`
  font-size: ${FONT_SIZE.xxs};
  padding-top: 0.5rem;
  color: rgb(101, 103, 107);
`;

export const TicketDetailHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    svg {
      height: 1.5rem;
      width: 1.5rem;
      stroke: var(--primary-color);
    }
  }

  .cursor {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .subject {
    color: #9fa4a5;
  }
`;

export const TicketStatusContainer = styled.div`
  cursor: pointer;
  .inactive {
    color: var(--text-light-color);
    opacity: 0.5;
  }
  .active {
    color: var(--primary-color);
  }
`;
