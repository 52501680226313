import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from '@app/api/table.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { Wrapper } from './History.styles';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { TFormData } from '@app/api/history.api';
import { historyDataSelector, searchHistory } from '@app/store/slices/historySlice';
import { formatDate, formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';
import { Dropdown, MenuProps, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { SelectInfo } from 'rc-menu/lib/interface';
import BaseLayout from '@app/components/layouts/BaseLayout';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import useCurrentRole from '@app/hooks/useCurrentRole';
import { BaseAlert } from '@app/components/common/BaseAlert/BaseAlert';

interface DataType {
  uuid: string;
  email?: string;
  type: string;
  amount: number;
  creator: {
    email: string;
    role: string;
  };
  message: string;
  date: Date;
}

const initialFormData: TFormData = {
  text: '',
  fromDate: '',
  toDate: '',
  changeType: null,
  email: '',
  size: 10,
  page: 0,
};

const HistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { isSeller } = useCurrentRole();

  const [valueSearch, setValueSearch] = useState<string>('');

  const { data: historyData, loading: loadingTable } = useAppSelector(historyDataSelector);

  const tableData = useMemo(() => {
    return {
      data: historyData?.content?.map((item, index) => {
        return {
          no: historyData.number * historyData.size + index + 1,
          uuid: item.uuid,
          email: item.email,
          type: item.changeType,
          amount: item.amount,
          message: item.source,
          creator: {
            email: item?.performedBy?.email ?? '',
            role: item?.performedBy?.role ?? '',
          },
          date: item.createdDate,
        };
      }),
      pagination: {
        total: historyData.totalElements,
        current: historyData.number + 1,
        pageSize: historyData.size || 10,
      },
    };
  }, [historyData]);

  useEffect(() => {
    dispatch(searchHistory(initialFormData));
  }, [dispatch]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
    },
    ...(isSeller
      ? []
      : [
          {
            title: 'Email',
            dataIndex: 'email',
          },
        ]),
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      render: (type: DataType['type']) => {
        if (type === 'ADD_MONEY')
          return (
            <BaseAlert
              style={{ width: '80px', background: '#00A48A', textAlign: 'center', margin: '0 auto' }}
              message={'Add'}
            />
          );

        if (type === 'SUBTRACT_MONEY')
          return (
            <BaseAlert
              style={{ width: '80px', background: '#E02734', textAlign: 'center', margin: '0 auto' }}
              message={'Subtract'}
            />
          );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'center',
      render: (amount: DataType['amount']) => {
        const price = getCurrencyPrice(formatNumberWithCommas(amount || 0), CurrencyTypeEnum['USD']);
        return <span>{price}</span>;
      },
    },
    {
      title: 'Message',
      dataIndex: 'message',
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      align: 'center',
      render: (data: DataType['creator']) => {
        return (
          <div>
            <p>{data.email}</p>
            <p>{data.role}</p>
          </div>
        );
      },
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (data: Date) => {
        const content = formatDate(data, 'internal');
        return <span>{content}</span>;
      },
    },
  ];

  const handleTableChange = (pagination: Pagination) => {
    dispatch(
      searchHistory({
        ...initialFormData,
        page: pagination.current ? pagination.current - 1 : 0,
      }),
    );
  };

  const handleChangeType = (value: SelectInfo) => {
    console.log({ value });
  };

  const items: MenuProps['items'] = [
    {
      key: 'all',
      label: 'All',
    },
    {
      key: 'add',
      label: 'Add Money',
    },
    {
      key: 'subtract',
      label: 'Subtract Money',
    },
  ];

  return (
    <BaseLayout>
      <>
        <PageTitle>{t('common.history')}</PageTitle>

        <Wrapper>
          <BaseCard padding={'0'} style={{ backgroundColor: 'unset' }}>
            <div
              style={{
                padding: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', gap: '15px' }}>
                <div>Filter by:</div>

                <Dropdown
                  menu={{
                    items,
                    selectable: true,
                    defaultSelectedKeys: ['all'],
                    onSelect: (item) => handleChangeType(item),
                  }}
                  trigger={['click']}
                >
                  <div style={{ cursor: 'pointer' }}>
                    <Space>
                      Type
                      <DownOutlined />
                    </Space>
                  </div>
                </Dropdown>
              </div>

              <NewsStyle.InputWrapper>
                <NewsStyle.SearchIcon />
                <NewsStyle.Input
                  placeholder={t('header.search')}
                  value={valueSearch}
                  onChange={(event) => {
                    setValueSearch(event.target.value);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      dispatch(
                        searchHistory({
                          ...initialFormData,
                          page: 0,
                          text: valueSearch,
                        }),
                      );
                    }
                  }}
                />
              </NewsStyle.InputWrapper>
            </div>

            <BaseTable
              columns={columns}
              dataSource={tableData.data}
              pagination={tableData.pagination}
              loading={loadingTable}
              onChange={handleTableChange}
              scroll={isSeller ? { x: 1000 } : { x: 1240 }}
              rowKey={(record) => record.uuid}
            />
          </BaseCard>
        </Wrapper>
      </>
    </BaseLayout>
  );
};

export default HistoryPage;
