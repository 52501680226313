import React from 'react';
import AdminProductPage from './AdminPage';
import SellerPage from './SellerPage';
import useCurrentRole from '@app/hooks/useCurrentRole';
import BaseLayout from '@app/components/layouts/BaseLayout';

const ProductPage: React.FC = () => {
  const { isAdmin, isSupport } = useCurrentRole();

  return <BaseLayout>{isAdmin || isSupport ? <AdminProductPage /> : <SellerPage />}</BaseLayout>;
};

export default ProductPage;
