import styled from 'styled-components';
import { BREAKPOINTS } from '@app/styles/themes/constants';

export const ReferencesWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;

  @media only screen and (max-width: ${BREAKPOINTS.sm - 0.02}px) {
    flex-direction: column-reverse;
    padding-top: 1rem;
    gap: 16px;
  }
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
  flex-wrap: wrap;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  a {
    height: 32px;
  }

  svg {
    font-size: 2rem;
  }
`;
