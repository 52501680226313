import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { TFormData, postHistory } from '@app/api/history.api';

export const searchHistory = createAsyncThunk('history/post', async (formData: TFormData, { rejectWithValue }) => {
  try {
    const { data } = await postHistory(formData);
    return data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

type TInitialData = {
  size: number;
  sort: never[];
  totalElements: number;
  totalPages: number;
  number: number;
  content: {
    amount: number;
    changeType: 'SUBTRACT_MONEY' | 'ADD_MONEY' | null;
    createdDate: Date;
    email: string;
    uuid: string;
    userUuid: string;
    source: string;
    performedBy: {
      email: string;
      fullname: string;
      role: string;
      uuid: string;
    };
  }[];
};

export const initialData: TInitialData = {
  size: 20,
  sort: [],
  totalElements: 0,
  totalPages: 0,
  number: 0,
  content: [],
};

const historySlice = createSlice({
  name: 'history',
  initialState: {
    data: initialData,
    loading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchHistory.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(searchHistory.rejected, (state) => {
        state.loading = false;
      });
  },
});

const { actions, reducer } = historySlice;

export default reducer;

export const historyData = (state: RootState) => state.history;

export const historyDataSelector = createSelector([historyData], (state) => state);
