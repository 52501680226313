export enum HttpStatusCode {
  _403 = 403,
  _404 = 404,
  _500 = 500,
}

export enum RefreshTokenState {
  START = 'start',
  CALLING = 'calling',
  STOP = 'stop',
}

// Storage key
export enum STORAGE_KEY {
  ACCESS_TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
}
