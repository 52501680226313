import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { Input } from '../orderDetail/Detail.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { updateOrderRequest, updateProductOrderRequest } from '@app/api/order.api';
import { useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ProductEdit, ProductEditContainer, ProductEditData } from './EditOrderModal.styles';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { BaseSelect, Option } from '@app/components/common/selects/BaseSelect/BaseSelect';
import { PositionsPositionSlt, PositionsTypeSlt, selectType } from '@app/components/catalog/common';
import TextArea from 'antd/lib/input/TextArea';

type formProps = {
  initData: updateOrderRequest;
};

function EditOrderProducts({ initData }: formProps) {
  const [initFormData, setInitFormData] = useState<updateOrderRequest>(initData);

  const handleRemoveProduct = (index: number) => {
    const data = { ...initFormData };
    data?.products?.splice(index, 1);
    setInitFormData(data);
  };

  const handlePushPosition = (index: number) => {
    const data = { ...initFormData };
    data?.products[index].positions.push({
      type: '',
      position: '',
    });
    setInitFormData(data);
  };

  const handleRemovePosition = (index: number, positionIndex: number) => {
    const data = { ...initFormData };
    data?.products[index].positions.splice(positionIndex, 1);
    setInitFormData(data);
  };

  useEffect(() => {
    setInitFormData(initData);
  }, [initFormData, initData]);

  return (
    <>
      {initFormData?.products.map((product: updateProductOrderRequest, index: number) => (
        <ProductEditContainer key={index}>
          <ProductEdit>
            <ProductEditData>
              <BaseCol span={6}>
                <BaseForm.Item
                  label="Product SKU"
                  name={['products', index, 'productSku']}
                  rules={[{ required: true }]}
                >
                  <Input></Input>
                </BaseForm.Item>
              </BaseCol>
              <BaseCol span={6}>
                <BaseForm.Item label="Quantity" name={['products', index, 'quantity']} rules={[{ required: true }]}>
                  <InputNumber block={true} min={1} type="number" />
                </BaseForm.Item>
              </BaseCol>
              <BaseCol span={6}>
                <BaseForm.Item label="Size" name={['products', index, 'size']} rules={[{ required: true }]}>
                  <BaseSelect>
                    <Option key={'OS'} value={'OS'}>
                      OS
                    </Option>
                    <Option key={'XS'} value={'XS'}>
                      XS
                    </Option>
                    <Option key={'S'} value={'S'}>
                      S
                    </Option>
                    <Option key={'M'} value={'M'}>
                      M
                    </Option>
                    <Option key={'L'} value={'L'}>
                      L
                    </Option>
                    <Option key={'XL'} value={'XL'}>
                      XL
                    </Option>
                    <Option key={'2XL'} value={'2XL'}>
                      2XL
                    </Option>
                    <Option key={'3XL'} value={'3XL'}>
                      3XL
                    </Option>
                    <Option key={'4XL'} value={'4XL'}>
                      4XL
                    </Option>
                    <Option key={'5XL'} value={'5XL'}>
                      5XL
                    </Option>
                  </BaseSelect>
                </BaseForm.Item>
              </BaseCol>
              <BaseCol span={6}>
                <BaseForm.Item label="Color" name={['products', index, 'color']} rules={[{ required: true }]}>
                  <Input></Input>
                </BaseForm.Item>
              </BaseCol>
              <BaseCol span={24}>
                <BaseForm.Item label="Note" name={['products', index, 'note']}>
                  <TextArea maxLength={500}></TextArea>
                </BaseForm.Item>
              </BaseCol>
              <BaseCol span={24}>
                <div
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '1rem' }}
                >
                  <span>Positions</span>
                  <BaseButton size="small" severity="success" onClick={() => handlePushPosition(index)}>
                    <PlusOutlined style={{ lineHeight: '0.9rem' }} />
                  </BaseButton>
                </div>
              </BaseCol>
              {product.positions.map((position, positionIndex) => {
                return (
                  <BaseCol key={positionIndex} span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <BaseCol span={6}>
                      <BaseForm.Item
                        label="Position"
                        name={['products', index, 'positions', positionIndex, 'position']}
                        rules={[{ required: true }]}
                      >
                        <BaseSelect>
                          {PositionsPositionSlt.map((item: selectType) => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </BaseSelect>
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseCol span={6}>
                      <BaseForm.Item
                        label="Type"
                        name={['products', index, 'positions', positionIndex, 'type']}
                        rules={[{ required: true }]}
                      >
                        <BaseSelect>
                          {PositionsTypeSlt.map((item: selectType) => (
                            <Option key={item.value} value={item.value}>
                              {item.label}
                            </Option>
                          ))}
                        </BaseSelect>
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseCol span={6}>
                      <BaseForm.Item
                        label="Design"
                        name={['products', index, 'positions', positionIndex, 'designOriginal']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseCol span={6}>
                      <BaseForm.Item
                        label="Mockup"
                        name={['products', index, 'positions', positionIndex, 'mockupLinkOriginal']}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseButton
                      size="small"
                      severity="error"
                      style={{ marginTop: '0.8rem', marginLeft: '0.5rem' }}
                      onClick={() => handleRemovePosition(index, positionIndex)}
                    >
                      <DeleteOutlined style={{ lineHeight: '0.9rem' }} />
                    </BaseButton>
                  </BaseCol>
                );
              })}
            </ProductEditData>
            <BaseButton size="small" severity="error" onClick={() => handleRemoveProduct(index)}>
              <DeleteOutlined style={{ lineHeight: '0.9rem' }} />
            </BaseButton>
          </ProductEdit>
        </ProductEditContainer>
      ))}
    </>
  );
}

export default EditOrderProducts;
