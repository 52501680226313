import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { FONT_WEIGHT, FONT_SIZE } from '@app/styles/themes/constants';

interface BaseInputProps {
  $height?: string;
}

export const Input = styled(AntInput)<BaseInputProps>`
  width: 100%;
  border-radius: 16px;
  height: ${(props) => (props.$height ? props.$height : '36px')};

  .ant-input-wrapper,
  .ant-input-affix-wrapper {
    height: 100%;
  }

  .ant-input-affix-wrapper {
    border-end-end-radius: 16px;
    border-start-end-radius: 16px;
  }

  .ant-input-group-addon {
    border-bottom-left-radius: 16px;
    border-start-start-radius: 16px;
  }

  .ant-input-group-addon:first-child,
  .ant-input-group-addon:last-child {
    color: var(--primary-color);
    font-weight: ${FONT_WEIGHT.semibold};
    font-size: ${FONT_SIZE.xs};
  }

  .ant-input-group-addon .ant-select {
    .ant-select-selection-item {
      color: var(--primary-color);
      font-weight: ${FONT_WEIGHT.semibold};
      font-size: ${FONT_SIZE.xs};
    }
  }

  .ant-select-arrow {
    color: var(--disabled-color);
  }
`;
