import { SearchOutlined } from '@ant-design/icons';
import { trackingUrls } from '@app/api/order.api';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { NFTCard } from '@app/components/nft-dashboard/common/NFTCard/NFTCard';
import { TitleBalanceText, TitleText, TrackingButton } from '@app/components/orders/Orders.styles';
import LOGO_USPS from '@app/assets/images/logo-usps.svg';

export interface TrackingCardPros {
  trackingId: string;
  status: string;
}

export const TrackingCard: React.FC<TrackingCardPros> = ({ trackingId }: TrackingCardPros) => {
  return (
    <BaseRow>
      <BaseCol span={24}>
        <TitleText level={2}>Tracking</TitleText>
      </BaseCol>

      <BaseCol span={24}>
        <NFTCard isSider padding={[40, 20]}>
          <BaseRow gutter={[30, 30]}>
            <BaseCol span={24}>
              <BaseRow gutter={[14, 14]}>
                <BaseCol span={24}>
                  <TitleBalanceText level={3}>
                    <img src={LOGO_USPS} />
                  </TitleBalanceText>
                </BaseCol>
              </BaseRow>
            </BaseCol>

            <BaseCol span={24}>
              <TrackingButton>
                <BaseButton
                  className={'tracking-button'}
                  href={trackingUrls + trackingId}
                  target={'_blank'}
                  type="primary"
                  icon={<SearchOutlined />}
                  disabled={trackingId == ''}
                >
                  Tracking Package
                </BaseButton>
              </TrackingButton>
            </BaseCol>
          </BaseRow>
        </NFTCard>
      </BaseCol>
    </BaseRow>
  );
};
