import styled from 'styled-components';

export const Textarea = styled.textarea`
  display: block;
  padding: 0.625rem;
  border-radius: 7px;
  border: 1px solid rgb(190, 192, 198);
  width: 100%;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #111827;
  background-color: var(--background-color);
  outline: none;
  height: clamp(6rem, 15vh, 13rem);
`;
