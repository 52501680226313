// GKC: 01J1RR3BMN8XMV65VSK6SHEW0W
import { Outlet } from 'react-router-dom';
import { Overlay } from '@app/components/common/Overlay/Overlay';
import { CSSProperties, ReactElement, useState } from 'react';
import { useResponsive } from '@app/hooks/useResponsive';
import { Header } from '@app/components/header/Header';
import MainSider from '../main/sider/MainSider/MainSider';
import { MainFooterStyle, MainStyle, RightMainStyle } from './BaseLayout.styles';
import { Layout } from 'antd';
import { References } from '@app/components/common/References/References';
import LiveChat from '@app/components/common/LiveChat';
import Loading from '@app/components/common/BaseLoading';

type BaseLayoutProps = {
  children?: ReactElement;
  loading?: boolean;
  isSidebar?: boolean;
  styles?: CSSProperties;
};

// define component
const BaseLayout: React.FC<BaseLayoutProps> = ({ children, loading, isSidebar = true, styles }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
  const { isDesktop } = useResponsive();

  const toggleSidebarCollapsed = () => setSidebarCollapsed(!sidebarCollapsed);

  return (
    <Layout style={{ height: '100vh' }}>
      <Header toggleSider={toggleSidebarCollapsed} isSiderOpened={!sidebarCollapsed} />

      <MainStyle $isNotDesktop={!isDesktop}>
        {isSidebar && <MainSider isCollapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} />}

        <RightMainStyle $isNotDesktop={!isDesktop} $styles={styles}>
          {children || <Outlet />}

          <MainFooterStyle $styles={styles}>
            <References />
          </MainFooterStyle>
        </RightMainStyle>
      </MainStyle>

      <LiveChat />

      <Loading loading={loading} />
      {!isDesktop && <Overlay onClick={toggleSidebarCollapsed} show={!sidebarCollapsed} />}
    </Layout>
  );
};

export default BaseLayout;
