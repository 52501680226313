import React from 'react';
import { ReactComponent as OrderIcon } from '@app/assets/icons/orders.svg';
import { ReactComponent as CatalogIcon } from '@app/assets/icons/catalog.svg';
import { ReactComponent as BillingIcon } from '@app/assets/icons/billing.svg';
import { ReactComponent as TicketIcon } from '@app/assets/icons/ticket.svg';
import { ReactComponent as HistoryIcon } from '@app/assets/icons/history.svg';
import { ReactComponent as DesignIcon } from '@app/assets/icons/design.svg';
import { ReactComponent as HomeIcon } from '@app/assets/icons/home.svg';
import { ReactComponent as UserManager } from '@app/assets/icons/user-manager.svg';
import { ReactComponent as MyConfigs } from '@app/assets/icons/config.svg';
import { Translation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface SidebarNavigationItem {
  key: string;
  title?: string;
  label: React.ReactNode;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  dashed?: boolean;
  type?: string;
}

type TSidebarItems = {
  t: (key: string) => string;
  isSubMenu?: boolean;
};

export const sidebarItems = ({ t, isSubMenu }: TSidebarItems): SidebarNavigationItem[] => {
  return [
    {
      key: 'dashboard',
      title: t('common.dashboard'),
      label: <Translation>{(t) => <Link to="/">{t('common.dashboard')}</Link>}</Translation>,
      icon: <HomeIcon />,
      url: '/',
    },
    {
      key: 'catalog',
      title: t('common.catalog'),
      label: isSubMenu ? (
        t('common.catalog')
      ) : (
        <Translation>{(t) => <Link to="/catalog/product">{t('common.catalog')}</Link>}</Translation>
      ),

      icon: <CatalogIcon />,
      ...(isSubMenu
        ? {
            children: [
              {
                title: t('common.category'),
                label: <Translation>{(t) => <Link to="/catalog/category">{t('common.category')}</Link>}</Translation>,
                key: 'category',
                url: '/catalog/category',
              },
              {
                title: t('common.product'),
                label: <Translation>{(t) => <Link to="/catalog/product">{t('common.product')}</Link>}</Translation>,
                key: 'product',
                url: '/catalog/product',
              },
            ],
          }
        : {
            url: '/catalog/product',
          }),
    },
    {
      key: 'orders',
      title: t('common.orders'),
      label: <Translation>{(t) => <Link to="/orders">{t('common.orders')}</Link>}</Translation>,
      url: '/orders',
      icon: <OrderIcon />,
    },
    {
      key: 'design-maker',
      title: t('common.designMaker'),
      label: <Translation>{(t) => <Link to="/design-maker">{t('common.designMaker')}</Link>}</Translation>,
      url: '/design-maker',
      icon: <DesignIcon />,
    },
    {
      key: 'history',
      title: t('common.history'),
      label: <Translation>{(t) => <Link to="/history">{t('common.history')}</Link>}</Translation>,
      url: '/history',
      icon: <HistoryIcon />,
    },
    {
      key: 'billing',
      title: t('common.billing'),
      label: <Translation>{(t) => <Link to="/billing">{t('common.billing')}</Link>}</Translation>,
      url: '/billing',
      icon: <BillingIcon />,
    },
    {
      label: '',
      key: 'divider',
      type: 'divider',
    },
    {
      key: 'tickets',
      title: t('common.tickets'),
      label: <Translation>{(t) => <Link to="/tickets">{t('common.tickets')}</Link>}</Translation>,
      url: '/tickets',
      icon: <TicketIcon />,
    },
    // {
    //   key: 'help',
    //   title: t('common.help'),
    //   label: <Translation>{(t) => <Link to="/help">{t('common.help')}</Link>}</Translation>,
    //   url: '/help',
    //   icon: <HelpIcon />,
    // },
    {
      key: 'user-manager',
      title: t('common.userManager'),
      label: <Translation>{(t) => <Link to="/user-manager">{t('common.userManager')}</Link>}</Translation>,
      url: '/user-manager',
      icon: <UserManager />,
    },
    {
      key: 'my-configs',
      title: 'My Configs',
      label: <Translation>{(t) => <Link to="/my-configs">My Configs</Link>}</Translation>,
      url: '/my-configs',
      icon: <MyConfigs />,
    },
  ];
};
