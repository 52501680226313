import React, { useState, useRef } from 'react';
import { Tabs, Button, Upload, Select, UploadFile } from 'antd';
import { UploadOutlined, ZoomInOutlined, ZoomOutOutlined, ScissorOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import tshirtWhite from '@app/assets/images/tshirt-white.png';
import BaseLayout from '@app/components/layouts/BaseLayout';

const { Option } = Select;

const MainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

const TshirtContainer = styled.div`
  width: 400px;
  height: 400px;
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
`;

const Sidebar = styled.div`
  width: 300px;
  padding: 20px;
  background-color: #f0f2f5;
`;

const RightToolsBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 24px;
`;

const DesignMakerPage = () => {
  const [currentTab, setCurrentTab] = useState('front');
  const [uploadedDesign, setUploadedDesign] = useState<string | null>(null);
  const [tshirtColor, setTshirtColor] = useState('#ffffff');
  const [image] = useImage(tshirtWhite);
  const [uploadedImage] = useImage(uploadedDesign || '');
  const stageRef = useRef(null);

  const [imageUpload, setImageUpload] = useState<HTMLImageElement | null>(null);

  const handleTabChange = (key: string) => {
    setCurrentTab(key);
  };

  const handleUpload = ({ file }: { file: UploadFile }) => {
    const reader = new FileReader();
    const fileObj = file.originFileObj as File; // Get the actual File object

    if (fileObj) {
      reader.onload = () => {
        const img = new window.Image();
        img.src = reader.result as string;
        img.onload = () => {
          setImageUpload(img); // Set the uploaded image
        };
      };
      reader.readAsDataURL(fileObj); // Read the file as a data URL
    }
  };

  const handleColorChange = (color: string) => {
    setTshirtColor(color);
  };

  // Array of tab items
  const tabItems = [
    {
      key: 'front',
      label: 'Front',
      children: (
        <TshirtContainer style={{ backgroundColor: tshirtColor }}>
          <Stage width={400} height={400} ref={stageRef}>
            <Layer>
              <KonvaImage image={image} width={400} height={400} />
              {uploadedDesign && <KonvaImage image={uploadedImage} width={200} height={200} x={100} y={100} />}
            </Layer>
          </Stage>
        </TshirtContainer>
      ),
    },
    {
      key: 'back',
      label: 'Back',
      children: (
        <TshirtContainer style={{ backgroundColor: tshirtColor }}>
          <Stage width={400} height={400} ref={stageRef}>
            <Layer>
              <KonvaImage image={image} width={400} height={400} />
            </Layer>
          </Stage>
        </TshirtContainer>
      ),
    },
    {
      key: 'left-sleeve',
      label: 'Left Sleeve',
      children: (
        <TshirtContainer style={{ backgroundColor: tshirtColor }}>
          <Stage width={400} height={400} ref={stageRef}>
            <Layer>
              <KonvaImage image={image} width={400} height={400} />
            </Layer>
          </Stage>
        </TshirtContainer>
      ),
    },
    {
      key: 'right-sleeve',
      label: 'Right Sleeve',
      children: (
        <TshirtContainer style={{ backgroundColor: tshirtColor }}>
          <Stage width={400} height={400} ref={stageRef}>
            <Layer>
              <KonvaImage image={image} width={400} height={400} />
            </Layer>
          </Stage>
        </TshirtContainer>
      ),
    },
  ];

  return (
    <BaseLayout>
      <MainContainer>
        {/* Left Sidebar */}
        <Sidebar>
          <h3>Technique</h3>
          <Button type="primary">Printing</Button>
          <Button style={{ marginLeft: '10px' }}>Embroidery</Button>

          <h3 style={{ marginTop: '20px' }}>Color</h3>
          <Select defaultValue="#ffffff" style={{ width: 120 }} onChange={handleColorChange}>
            <Option value="#ffffff">White</Option>
            <Option value="#000000">Black</Option>
            <Option value="#ff0000">Red</Option>
            <Option value="#00ff00">Green</Option>
            <Option value="#0000ff">Blue</Option>
          </Select>

          <h3 style={{ marginTop: '20px' }}>Size</h3>
          <Select defaultValue="M" style={{ width: 120 }}>
            <Option value="S">S</Option>
            <Option value="M">M</Option>
            <Option value="L">L</Option>
            <Option value="XL">XL</Option>
          </Select>

          <h3 style={{ marginTop: '20px' }}>Upload Design</h3>
          <Upload
            name="file"
            showUploadList={false}
            beforeUpload={(file) => {
              handleUpload({ file });
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Sidebar>

        {/* T-shirt Container */}
        <div>
          <Tabs defaultActiveKey="front" onChange={handleTabChange} items={tabItems} />
        </div>

        {/* Right Tools Bar */}
        <RightToolsBar>
          <Button icon={<ZoomInOutlined />}>Zoom In</Button>
          <Button icon={<ZoomOutOutlined />}>Zoom Out</Button>
          <Button icon={<ScissorOutlined />}>Crop</Button>
        </RightToolsBar>
      </MainContainer>
    </BaseLayout>
  );
};

export default DesignMakerPage;
