import { Spin } from 'antd';
import { WapperStyle } from './BaseLoading.styles';
import { CSSProperties } from 'react';

type LoadingProps = {
  loading?: boolean;
  styles?: CSSProperties;
};

const Loading: React.FC<LoadingProps> = ({ loading, styles }) => {
  if (!loading) return null;

  return (
    <WapperStyle $styles={styles}>
      <Spin />
    </WapperStyle>
  );
};

export default Loading;
