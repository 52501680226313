import styled, { css } from 'styled-components';
import { NFTCard } from '@app/components/nft-dashboard/common/NFTCard/NFTCard';
import { FONT_SIZE, FONT_WEIGHT, media, BORDER_RADIUS } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

interface CardInternalProps {
  $img: string;
  $isMobile: boolean;
  $isTablet: boolean;
}

export const NftImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
  flex-shrink: 0;
  transition: all 0.3s ease-in-out;
`;

export const Title = styled(BaseTypography.Title)<{ $isCarousel: boolean }>`
  position: relative;
  animation: titleOut 0.5s;

  &.ant-typography {
    font-size: ${FONT_SIZE.md};
  }

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;

  ${(props) =>
    props.$isCarousel &&
    css`
      -webkit-line-clamp: 1;
    `}
`;

export const NftInfo = styled.div`
  padding: 1rem 1.25rem 1.5rem;
  flex: 1;

  display: flex;
  flex-direction: column;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InfoFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  flex-shrink: 0;
`;

export const CurrentBidWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const InfoText = styled.p`
  display: block;
  transition: all 0.5s ease;
  letter-spacing: 0.02em;
  margin-bottom: 0;
  margin-top: auto;

  font-weight: ${FONT_WEIGHT.regular};

  font-size: ${FONT_SIZE.xs};

  color: var(--text-nft-light-color);

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.md};
  }
`;

export const CurrentBid = styled(InfoText)`
  font-size: ${FONT_SIZE.xs};

  color: var(--text-main-color);
  display: flex;
  gap: 0.5rem;
  .color-cricle {
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-color: var(--color);
    border: 1px solid var(--border-nft-color);
  }
`;

export const BidCrypto = styled.span`
  transition: all 0.5s ease;

  font-size: ${FONT_SIZE.xs};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.md};
  }
`;

export const Bid = styled(CurrentBid)`
  align-items: center;
  font-size: ${FONT_SIZE.md};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.semibold};

  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.md};
  }

  .mt-5px {
    margin-top: 5px;
  }
`;

export const Card = styled(NFTCard)<CardInternalProps>`
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  & .ant-card-body {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    & {
      box-shadow: var(--box-shadow-hover);
    }

    img {
      transform: scale(1.1);
    }
  }

  @keyframes imgIn {
    99% {
      transform: scale(2);
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes imgOut {
    0% {
      transform: scale(2);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes titleIn {
    0% {
      left: 0;
    }

    100% {
      left: 50%;
      transform: translate(-50%, -50%) scale(1.5);
    }
  }

  @keyframes titleOut {
    0% {
      left: 50%;
      transform: translate(-50%, -50%) scale(1.5);
    }

    100% {
      left: 0;
    }
  }
`;
