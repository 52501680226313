import axios from 'axios';
import { httpApi } from './http.api';
import { ColorType } from './product.api';
import { readToken } from '@app/services/localStorage.service';

export type orderImport = {
  file: File[];
};

export const urlTemplacre =
  'https://docs.google.com/spreadsheets/d/19rB77bQBkJwflhXRinCiZeiGkKAH2wM-SH-lzhO53OE/edit#gid=0';

export const trackingUrls = 'https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLabels=';

export type paramsOrder = {
  searchText: string;
  fromDate?: string | null | Date;
  toDate?: string | null | Date;
  status?: string[];
  source?: string[];
  trackingLabel?: string;
  design?: string;
  trackingId?: string;
  page: number;
  size: number;
};

export type apprpveOrder = {
  status: string;
  trackingId: string;
  shippingCarrier: string;
};

export type orderType = {
  uuid: string;
  orderId: string;
  totalAmount: number;
  source: string;
  status: string;
  createdDate: string;
  trackingUrl: string | null;
  trackingLabel: string | null;
  owner?: string;
  ownerName?: string;
};

export type detailOrder = {
  uuid: string;
  source: string;
  ownerUuid: string;
  ownerEmail: string;
  orderId: string;
  status: string;
  total: number;
  shippingFees: number;
  createdDate: string;
  createdBy: string;
  updatedDate: string;
  updatedBy: string;
  shippingName: string;
  shippingAddress: string;
  shippingPhone?: string;
  trackingId: string | null;
  shippingCarrier: string | null;
  trackingLabel: string;
  products: detailOrderProduct[];
  extraFee?: number;
  designFee: number;
};

export type detailOrderProduct = {
  uuid: string;
  orderUuid: string;
  productSku: string;
  productUuid: string;
  quantity: number;
  unitPrice: number;
  unitPriceDecreased: number;
  amount: number;
  color: ColorType;
  size: string;
  position?: string;
  product?: string;
  positions: detailOrderPosition[];
  name?: string;
  shippingFee?: number;
  extraFee?: number;
  designFee: number;
  note: string;
};

export type detailOrderPosition = {
  position: string;
  design?: string;
  type: string;
  mockupLink?: string;
  designOriginal?: string;
  mockupLinkOriginal?: string;
};

export type updateOrderProductPosition = {
  orderUuid: string;
  productSku: string;
  position: detailOrderPosition;
  size: string;
  color: ColorType;
};

export type updateOrderProductPositionResponse = {
  designUrl: string;
  mockupUrl: string;
};

export type updateProductOrderRequest = {
  productSku: string;
  quantity: number;
  color: string;
  size: string;
  positions: detailOrderPosition[];
  note: string;
};

export type updateOrderRequest = {
  uuid: string;
  shippingName: string;
  shippingAddress: string;
  shippingPhone?: string;
  trackingId?: string;
  trackingLabel?: string;
  products: updateProductOrderRequest[];
};

export type updateProductFeesOrderRequest = {
  shippingFees: number;
  extraFees: number;
  orderDetailUuid: string;
};

export type updateFeesOrderRequest = {
  uuid: string;
  products: updateProductFeesOrderRequest[];
};

export const getOrder = async (params: paramsOrder) => {
  const res = await httpApi.post('/api/v1/order/search', params);
  return res.data;
};

export const getDetailOrder = async (uuid: string) => {
  const res = await httpApi.get(`/api/v1/order/${uuid}`);
  return res.data;
};

export const ApproveOrder = (uuid: string, status: apprpveOrder) => {
  return httpApi.post(`/api/v1/order/${uuid}/approve`, status);
};

export const getReplaceLink = async (body: updateOrderProductPosition) => {
  const res = await httpApi.post(`/api/v1/order/find-replace`, body);
  return res.data;
};

export const ReplaceLink = async (body: updateOrderProductPosition) => {
  const res = await httpApi.put(`/api/v1/order/replace-link`, body);
  return res.data;
};

export const SearchReplaceLink = async (s: string) => {
  const res = await httpApi.get(`/api/v1/order/search-replace`, {
    params: {
      s: s,
    },
  });
  return res.data;
};

export const UpdateOrder = (uuid: string, data: updateOrderRequest) => {
  return httpApi.post(`/api/v1/order/${uuid}/update`, data);
};

export const Export = async (params: paramsOrder) => {
  const token = readToken();
  const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v1/order/export`, params, {
    responseType: 'blob', // Set the response type to blob
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const CancelOrder = (uuid: string) => {
  return httpApi.put(`/api/v1/order/${uuid}/cancel`);
};

export const EditFees = (uuid: string, data: updateFeesOrderRequest) => {
  return httpApi.put(`/api/v1/order/${uuid}/fees/edit`, data);
};
