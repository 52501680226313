import { ResProduct, getProduct, paramsType } from '@app/api/product.api';
import CardItems from '@app/components/common/BaseProductCard/BaseProductCard';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useMounted } from '@app/hooks/useMounted';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { PagingationCustom } from '@app/components/catalog/conmon.styles';

interface Pagination {
  pageNumber: number;
  perPage: number;
  totalPage: number;
  totalElement: number;
}
const initPage: Pagination = {
  pageNumber: 0,
  perPage: 20,
  totalPage: 1,
  totalElement: 0,
};
const stateToString = (paging: Pagination, s: string): string => {
  return `page=${paging.pageNumber}&size=${paging.perPage}&s=${s}`;
};
const SellerPage: React.FC = () => {
  const { isMounted } = useMounted();
  const { t } = useTranslation();
  const [valueSearch, setValueSearch] = useState<string>('');
  const [searchIpt, setSearchIpt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ResProduct[]>([]);
  const [page, setPage] = useState<Pagination>(initPage);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const wasRedirected = useRef(false);
  const [isRedirect, setIsRedirect] = useState<boolean>(true);

  const fetch = useCallback(
    async (paging: Pagination) => {
      setIsLoaded(false);
      let params: paramsType = {
        sort: 'createdDate,desc',
        page: paging.pageNumber,
        size: paging.perPage,
      };
      if (valueSearch) {
        params = {
          ...params,
          s: valueSearch,
        };
      }
      setLoading(true);
      try {
        const res = await getProduct(params);
        if (isMounted.current) {
          setPage((prePage) => ({
            ...prePage,
            pageNumber: res.pageable.pageNumber,
            totalPage: res.totalPages,
            totalElement: res.totalElements,
          }));
          setIsLoaded(true);
          setData(res.content);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    [isMounted, valueSearch],
  );
  useEffect(() => {
    wasRedirected.current = true;
  }, []);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const s = queryParams.get('s');
    if (wasRedirected.current) {
      if (s && isRedirect) {
        setValueSearch(s);
        setSearchIpt(s);
        setIsRedirect(false);
      }
      const page = queryParams.get('page') || '0';
      const size = queryParams.get('size') || '20';
      const pagination: Pagination = {
        pageNumber: parseInt(page),
        perPage: parseInt(size),
        totalPage: 1,
        totalElement: 0,
      };
      setPage(pagination);
      fetch(pagination);
    } else {
      fetch(initPage);
      setPage(initPage);
    }
  }, [isRedirect, fetch]);

  // useEffect(() => {
  //   const params = {
  //     sort: 'createdDate,desc',
  //     page: 0,
  //     size: 1000,
  //   };
  // }, []);

  const handleSearch = (value?: string) => {
    if (value === '') {
      setValueSearch('');
    } else {
      setValueSearch(searchIpt);
    }
  };

  const getCurrentUrl = (): string => {
    const params = stateToString(page, valueSearch);
    return params;
  };

  return (
    <div>
      <BaseCard padding={0} style={{ padding: '1.5rem 1rem 1rem 1rem', background: '#f7f7f7' }}>
        <PageTitle>{t('common.product')}</PageTitle>
        <div style={{ display: 'flex', marginBottom: '1rem', gap: '1rem', flexWrap: 'wrap' }}>
          <NewsStyle.InputWrapper>
            <NewsStyle.SearchIcon onClick={() => handleSearch()} />
            <NewsStyle.Input
              placeholder={t('header.search')}
              value={searchIpt}
              onChange={(event) => {
                const value = event.target.value;
                if (value === '') {
                  handleSearch('');
                }
                setSearchIpt(value);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
          </NewsStyle.InputWrapper>
        </div>
        <BaseSpin spinning={loading}>
          {isLoaded && <CardItems items={data} preState={getCurrentUrl()} />}
          <div style={{ display: 'flex', justifyContent: 'end', padding: '1rem 0', marginTop: '1rem' }}>
            <PagingationCustom
              current={page.pageNumber + 1}
              total={page.totalElement}
              pageSize={page.perPage}
              onChange={(pageIndex, pageSize) => {
                fetch({
                  pageNumber: pageIndex - 1,
                  perPage: pageSize,
                  totalElement: 0,
                  totalPage: 0,
                });
              }}
              onShowSizeChange={(current, size) => {
                fetch({
                  pageNumber: 0,
                  perPage: size,
                  totalElement: 0,
                  totalPage: 0,
                });
              }}
            />
          </div>
        </BaseSpin>
      </BaseCard>
    </div>
  );
};

export default SellerPage;
