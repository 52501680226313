import React, { ReactElement, useEffect } from 'react';
// import { Outlet, useNavigate } from 'react-router-dom';
import * as S from './AuthLayout.styles';
import loginBackground from '@app/assets/images/login_background.png';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type AuthLayoutProps = {
  children?: ReactElement;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  //Will delete in the future
  // const navigate = useNavigate();
  // const location = useLocation();
  // const [searchParams] = useSearchParams();
  // const isCallback = searchParams.get('callBack');
  // useEffect(() => {
  //   if (isCallback) return;
  //   navigate('/auth/login', { replace: true });
  // }, [isCallback, navigate]);

  return (
    <>
      {/* {isCurrentLayout ? (
        <S.Wrapper2>
          <div className="left">
            <img src={loginBackground} alt="" />
          </div>
          <div className="right">
            <S.LoginWrapper>
              <Outlet />
            </S.LoginWrapper>
          </div>
        </S.Wrapper2>
      ) : (
        <S.Wrapper>
          <S.BackgroundWrapper>
            <S.LoginWrapper>
              <Outlet />
            </S.LoginWrapper>
          </S.BackgroundWrapper>
        </S.Wrapper>
      )} */}
      <S.Wrapper2>
        <div className="left">
          <img src={loginBackground} alt="" />
        </div>
        <S.LoginBgWrapper className="right">
          <S.LoginWrapper>{children}</S.LoginWrapper>
        </S.LoginBgWrapper>
      </S.Wrapper2>
    </>
  );
};

export default AuthLayout;
