import styled from 'styled-components';

export const SectionWrapper = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const BannerWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16 / 5;
  overflow: hidden;
  border-radius: 8px;
`;

export const ImageStyles = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
