import styled, { css } from 'styled-components';
import { Table as AntdTable } from 'antd';
import { BORDER_RADIUS, FONT_SIZE } from '@app/styles/themes/constants';

export const Table = styled(AntdTable)<{ $isHoverRow?: boolean }>`
  & thead {
    background: #f3f3f3;
  }

  & thead .ant-table-cell {
    color: #303030;
    font-size: 13px;
    line-height: 1.25rem;
    font-weight: 600;

    & .anticon {
      color: var(--primary-color);
    }
  }

  ${(props) =>
    props.$isHoverRow &&
    css`
      & tbody .ant-table-row.ant-table-row-level-0 {
        cursor: pointer;
      }
    `}

  & tbody .ant-table-cell {
    color: var(--text-main-color);
    font-size: 15px;
    line-height: 1.25rem;
  }

  & tbody .ant-table-row-expand-icon {
    min-height: 1.25rem;
    min-width: 1.25rem;
    border-radius: 0.1875rem;
    margin-top: 0;
  }

  & .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next,
  .ant-pagination-item,
  .ant-pagination-options {
    min-width: 2.0625rem;
    height: 2.0625rem;
    line-height: 2.0625rem;
    border-radius: 0;
    font-size: ${FONT_SIZE.xs};
  }

  & .ant-pagination-options {
    .ant-select-selector {
      height: 2.0625rem;
      align-items: center;

      .ant-select-selection-search {
        top: unset;
      }

      .ant-select-selection-search-input {
        height: 2.0625rem;
      }

      .ant-select-selection-item {
        line-height: 2.0625rem;
      }
    }
  }

  & .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0;
  }

  & .ant-checkbox-inner {
    border-radius: 0.1875rem;
    height: 1.25rem;
    width: 1.25rem;
    border: 1px solid var(--primary-color);
  }

  & .editable-row .ant-form-item-explain {
    position: absolute;
    top: 100%;
    font-size: 0.75rem;
  }

  .ant-table-column-sort {
    background-color: transparent;
  }

  .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: var(--disabled-color);
  }

  .ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }

  .ant-pagination.ant-pagination-disabled {
    .ant-pagination-item-link,
    .ant-pagination-item a {
      color: var(--disabled-color);
    }
  }

  .ant-pagination {
    margin: 1rem;

    li,
    li.ant-pagination-prev button,
    li.ant-pagination-next button {
      border-radius: ${BORDER_RADIUS};
    }
  }
`;
