import React, { useMemo, useState } from 'react';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import SiderMenu from '../SiderMenu/SiderMenu';
import { CollapseStyle, SiderLogoMobileStyle, TopUpAndOrderStyle } from './MainSider.styles';
import { LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import logo from 'assets/logo-full.png';
import { HeaderCreateOrderBtn, HeaderTopUpBtn } from '@app/components/header/Header.styles';
import { TopUpBalanceModal } from '@app/components/nft-dashboard/Balance/components/TopUpBalanceModal/TopUpBalanceModal';
import { topup } from '@app/api/topUp.api';
import { TopUpFormData } from '@app/components/nft-dashboard/Balance/interfaces/interfaces';
import { notificationController } from '@app/controllers/notificationController';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { CurrencyTypeEnum } from '@app/interfaces/interfaces';
import { formatNumberWithCommas, getCurrencyPrice } from '@app/utils/utils';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { desktopOnly, isDesktop, isMobile } = useResponsive();

  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const user = useAppSelector((state) => state.user.user);

  const price = getCurrencyPrice(formatNumberWithCommas(user?.walletBalance || 0), CurrencyTypeEnum['USD']);

  const onFinish = (values: TopUpFormData) => {
    setLoading(true);
    topup({
      amount: values.amount,
      evdUrl: values.evidence.file.response.data,
      paymentChannel: values.paymentChannel,
      transactionId: values.transactionId,
    })
      .then(() => {
        setTimeout(() => {
          notificationController.success({ message: t('topup.success') });
          setModalOpen(false);
        }, 1000);
      })
      .finally(() => setLoading(false));
  };

  const isCollapsible = useMemo(() => desktopOnly, [desktopOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  return (
    <>
      <S.Sider
        id="mainSider"
        trigger={null}
        collapsed={isDesktop ? !isCollapsed : isCollapsed}
        collapsedWidth={isDesktop ? 80 : 0}
        collapsible={isCollapsible}
        width={260}
        $isNotDesktop={!isDesktop}
        {...props}
      >
        {!isDesktop && (
          <SiderLogoMobileStyle onClick={() => setCollapsed(true)}>
            <Link to="/">
              <img src={logo} alt="egfulfill" width={176} />
            </Link>
          </SiderLogoMobileStyle>
        )}

        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} />

          {!isDesktop ? (
            <TopUpAndOrderStyle>
              {/* <HeaderTopUpBtn
                $isFullWidth
                onClick={() => {
                  setCollapsed(true);
                  setModalOpen(true);
                }}
              >
                Top Up
              </HeaderTopUpBtn> */}

              <HeaderTopUpBtn
                $isMobile={isMobile}
                onClick={() => {
                  setModalOpen(true);
                  setCollapsed(true);
                }}
                type="text"
              >
                <span className="circle" aria-hidden="true">
                  <span className="limit">5/20</span>
                </span>

                <p className="balance">
                  Balance:<span className="price"> {price}</span>
                </p>
                <PlusOutlined />
              </HeaderTopUpBtn>

              {/* TODO: wait for the next phase */}
              {/* <HeaderCreateOrderBtn $isFullWidth>Create Order</HeaderCreateOrderBtn> */}
            </TopUpAndOrderStyle>
          ) : (
            <CollapseStyle onClick={toggleSider}>
              <LeftOutlined rotate={isCollapsed ? 0 : 180} />
            </CollapseStyle>
          )}
        </S.SiderContent>
      </S.Sider>

      {isModalOpen && (
        <TopUpBalanceModal isOpen onCancel={() => setModalOpen(false)} onFinish={onFinish} loading={loading} />
      )}
    </>
  );
};

export default MainSider;
