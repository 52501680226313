import styled, { css } from 'styled-components';
import { media } from '@app/styles/themes/constants';
import { LAYOUT } from '@app/styles/themes/constants';
import { BaseLayout } from '@app/components/common/BaseLayout/BaseLayout';

export const Sider = styled(BaseLayout.Sider)<{ $isNotDesktop: boolean }>`
  position: relative;
  left: 0;
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  color: var(--primary-color);
  overflow: visible;

  z-index: 5;

  ${(props) =>
    props.$isNotDesktop &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      left: unset;
      min-height: 100vh;
      max-height: 100vh;
      border-right: unset;
    `}
`;

export const CollapseStyle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  padding: 16px;
  cursor: pointer;
  background-color: var(--collapse-background-color);

  svg path {
    fill: var(--text-sider-primary-color);
  }
`;

export const TopUpAndOrderStyle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  background-color: var(--white);
  border-top: 1px solid #ddd;

  padding: 16px;
`;

export const SiderContent = styled.div`
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  min-height: calc(100vh - ${LAYOUT.mobile.headerHeight});
  max-height: calc(100vh - ${LAYOUT.mobile.headerHeight});

  @media only screen and ${media.xl} {
    min-height: calc(100vh - ${LAYOUT.desktop.headerHeight});
    max-height: calc(100vh - ${LAYOUT.desktop.headerHeight});

    ul.ant-menu.ant-menu-inline-collapsed {
      width: 100%;
    }
  }
`;

export const SiderLogoMobileStyle = styled.div`
  background-color: var(--layout-header-bg-color);
  height: ${LAYOUT.mobile.headerHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const BrandSpan = styled.span`
  margin: 0 1rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: var(--white);
`;
