import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as NewsStyle from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { ColumnsType } from 'antd/es/table';
import { BaseTable } from '@app/components/common/BaseTable/BaseTable';
import { Pagination } from '@app/api/table.api';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { formatDate } from '@app/utils/utils';
import BaseLayout from '@app/components/layouts/BaseLayout';
import { Wrapper } from '../History/History.styles';
import { changeMyConfigs, fetchMyConfigs, myConfigsData, setMyConfigKey } from '@app/store/slices/myConfigsSlice';
import { ParamsMyConfig } from '@app/api/myConfig.api';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormInput } from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';

export type MyConfigsFormData = {
  value: string;
};

interface DataType {
  key: string;
  value: string;
  description: string;
  updatedDate: Date | string;
}

export const initialFormData: ParamsMyConfig = {
  s: '',
  page: 0,
  size: 10,
};

const initialValues: MyConfigsFormData = {
  value: '',
};

const MyConfigsPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = BaseForm.useForm();

  const [valueSearch, setValueSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const { myConfigs, loading } = useAppSelector(myConfigsData);

  useEffect(() => {
    if (value) form.setFieldsValue({ value });
  }, [value, form]);

  const handleCancel = () => {
    setOpen(false);
    dispatch(setMyConfigKey(''));
    form.setFieldsValue(initialValues);
    form.resetFields();
    setValue('');
  };

  const handleClickItem = (record: DataType) => {
    setValue(record.value);
    setOpen(true);
    dispatch(setMyConfigKey(record.key));
  };

  const tableData = useMemo(() => {
    return {
      data: myConfigs?.content?.map((item, index) => {
        return {
          no: myConfigs?.number * myConfigs?.size + index + 1,
          key: item.key,
          value: item.value,
          description: item.description,
          updatedDate: item?.updatedDate || '',
        };
      }),
      pagination: {
        total: myConfigs?.totalElements ?? 0,
        current: myConfigs?.number || 0 + 1,
        pageSize: myConfigs?.size || 10,
      },
    };
  }, [myConfigs?.content, myConfigs?.number, myConfigs?.size, myConfigs?.totalElements]);

  useEffect(() => {
    dispatch(fetchMyConfigs(initialFormData));
  }, [dispatch]);

  const columns: ColumnsType<DataType> = [
    {
      title: 'No.',
      dataIndex: 'no',
      align: 'center',
    },
    {
      title: 'Key',
      dataIndex: 'key',
    },
    {
      title: 'Value',
      dataIndex: 'value',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Updated Date',
      dataIndex: 'updatedDate',
      render: (data: Date | string) => {
        const content = formatDate(data, 'internal');
        return <span>{content}</span>;
      },
    },
  ];

  const handleTableChange = (pagination: Pagination) => {
    dispatch(
      fetchMyConfigs({
        ...initialFormData,
        page: pagination.current ? pagination.current - 1 : 0,
      }),
    );
  };

  const onFinish = (values: MyConfigsFormData) => {
    setOpen(false);

    if (value === values.value) return;
    dispatch(changeMyConfigs(values.value));
  };

  return (
    <BaseLayout>
      <>
        <PageTitle>My Configs</PageTitle>
        <Wrapper>
          <BaseCard padding={'0'} style={{ backgroundColor: 'unset' }}>
            <div
              style={{
                padding: '1.25rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <NewsStyle.InputWrapper>
                <NewsStyle.SearchIcon />
                <NewsStyle.Input
                  placeholder={t('header.search')}
                  value={valueSearch}
                  onChange={(event) => {
                    setValueSearch(event.target.value);
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === 'Enter') {
                      dispatch(
                        fetchMyConfigs({
                          ...initialFormData,
                          page: 0,
                          s: valueSearch,
                        }),
                      );
                    }
                  }}
                />
              </NewsStyle.InputWrapper>
            </div>

            <BaseTable
              columns={columns}
              dataSource={tableData.data}
              pagination={tableData.pagination}
              loading={loading}
              onChange={handleTableChange}
              scroll={{ x: 1000 }}
              rowKey={(record) => record.key}
              onRow={(record) => {
                return {
                  onClick: () => handleClickItem(record),
                };
              }}
              isHoverRow
            />
          </BaseCard>
        </Wrapper>

        {open && (
          <BaseModal title={'Edit Value'} width={500} open onCancel={handleCancel} footer={null} destroyOnClose>
            <BaseSpin spinning={loading}>
              <BaseForm
                form={form}
                name="editValue"
                onFinish={onFinish}
                validateTrigger="onBlur"
                initialValues={initialValues}
              >
                <BaseForm.Item name="value" label="Value">
                  <FormInput placeholder="Value" />
                </BaseForm.Item>

                <BaseForm.Item>
                  <BaseButton type="primary" htmlType="submit" block disabled={loading}>
                    OK
                  </BaseButton>
                </BaseForm.Item>
              </BaseForm>
            </BaseSpin>
          </BaseModal>
        )}
      </>
    </BaseLayout>
  );
};

export default MyConfigsPage;
