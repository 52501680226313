import { BaseCarousel } from '@app/components/common/BaseCarousel/Carousel';
import { useRef } from 'react';
import TikTok from '@app/assets/images/tik-tok.png';
import { BannerWrapper, ImageStyles } from './Banner.styles';

const BannerDashboard = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const sliderRef = useRef<any>();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    centerMode: false,
  };

  return (
    <BaseCarousel ref={sliderRef} {...settings} adaptiveHeight>
      <BannerWrapper>
        <ImageStyles src={TikTok} alt="TikTok" />
      </BannerWrapper>

      <BannerWrapper>
        <ImageStyles src={TikTok} alt="TikTok" />
      </BannerWrapper>

      <BannerWrapper>
        <ImageStyles src={TikTok} alt="TikTok" />
      </BannerWrapper>

      <BannerWrapper>
        <ImageStyles src={TikTok} alt="TikTok" />
      </BannerWrapper>
    </BaseCarousel>
  );
};

export default BannerDashboard;
