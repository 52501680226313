import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { Input } from '../orderDetail/Detail.styles';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { detailOrder, updateOrderRequest } from '@app/api/order.api';
import { BaseModal } from '@app/components/common/BaseModal/BaseModal';
import { useEffect, useState } from 'react';
import { BaseSpin } from '@app/components/common/BaseSpin/BaseSpin';
import { ModalContainer, ModalFooter, ModalMain } from '@app/components/catalog/conmon.styles';
import { PlusOutlined } from '@ant-design/icons';
import EditOrderProducts from './EditOrderProducts';

type formProps = {
  handleSubmit: (values: detailOrder) => void;
  detail: detailOrder;
  initData: updateOrderRequest;
  checkStatus: { [key: string]: number };
  status: string[];
  isOpen: boolean;
  onOpenChange: () => void;
};

function EditOrderModal({ handleSubmit, detail, initData, checkStatus, status, isOpen, onOpenChange }: formProps) {
  const [loading, setLoading] = useState(false);
  const [initFormData, setInitFormData] = useState<updateOrderRequest>(initData);

  const handlePushProduct = () => {
    const data = { ...initFormData };
    data.products.push({
      productSku: '',
      quantity: 1,
      color: '',
      size: '',
      positions: [],
      note: '',
    });
    setInitFormData(data);
  };
  useEffect(() => {
    setInitFormData(initData);
  }, [initData]);

  return (
    <>
      <BaseCol span={24}>
        <BaseModal
          title={`Edit Order: ${detail.orderId}`}
          width={1550}
          open={isOpen}
          onCancel={onOpenChange}
          footer={null}
          destroyOnClose
        >
          <BaseSpin spinning={loading}>
            <ModalContainer>
              <ModalMain>
                <BaseForm id={'edit-form'} layout="vertical" onFinish={handleSubmit} initialValues={initFormData}>
                  <BaseRow gutter={8}>
                    <BaseCol span={24}>
                      <BaseForm.Item label="Shipping Name" name="shippingName" rules={[{ required: true }]}>
                        <Input value={detail.shippingName || ''}></Input>
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseCol span={24}>
                      <BaseForm.Item label="Shipping Address" name="shippingAddress" rules={[{ required: true }]}>
                        <Input value={detail.shippingAddress || ''}></Input>
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseCol span={12}>
                      <BaseForm.Item label="Shipping Phone" name="shippingPhone">
                        <Input value={detail.shippingPhone || ''}></Input>
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseCol span={12}>
                      <BaseForm.Item label="Tracking Id" name="trackingId">
                        <Input value={detail.trackingId || ''}></Input>
                      </BaseForm.Item>
                    </BaseCol>
                    <BaseCol span={24}>
                      <BaseForm.Item label="Tracking label" name="trackingLabel">
                        <Input value={detail.trackingLabel || ''}></Input>
                      </BaseForm.Item>
                    </BaseCol>
                  </BaseRow>
                  <BaseRow gutter={[8, 16]}>
                    <BaseCol span={24}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>Products</span>
                        <BaseButton size="small" severity="success" onClick={handlePushProduct}>
                          <PlusOutlined style={{ lineHeight: '0.9rem' }} />
                        </BaseButton>
                      </div>
                    </BaseCol>
                    <BaseCol span={24} style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
                      <EditOrderProducts initData={initFormData} />
                    </BaseCol>
                  </BaseRow>
                </BaseForm>
              </ModalMain>
              <ModalFooter>
                <div style={{ display: 'flex', gap: '1rem' }}>
                  <BaseButton type="ghost" onClick={onOpenChange}>
                    Cancel
                  </BaseButton>
                  <BaseButton type="primary" htmlType="submit" form={'edit-form'}>
                    Update
                  </BaseButton>
                </div>
              </ModalFooter>
            </ModalContainer>
          </BaseSpin>
        </BaseModal>
      </BaseCol>
    </>
  );
}

export default EditOrderModal;
