import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
// import { useAppDispatch } from '@app/hooks/reduxHooks';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './VerifyAccountForm.styles';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { VfACCType, readVerifyACC } from '@app/services/localStorage.service';
import AuthLayout from '@app/components/layouts/AuthLayout/AuthLayout';
// import { Statistic } from 'antd';

export const VerifyPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateBack = useCallback(() => navigate('/auth/login'), [navigate]);

  // const { Countdown } = Statistic;
  const [verifyInfo, setVerifyInfo] = useState<VfACCType | null>();
  // const [isLoading, setLoading] = useState(false);
  // const [isResend, setIsResend] = useState(false);
  // const dispatch = useAppDispatch();
  useEffect(() => {
    setVerifyInfo(readVerifyACC());
  }, []);

  return (
    <AuthLayout>
      <Auth.FormWrapper>
        <BaseForm layout="vertical" requiredMark="optional">
          <Auth.BackWrapper onClick={navigateBack}>
            <Auth.BackIcon />
            {/* {t('common.back')} */}
            Login
          </Auth.BackWrapper>
          <S.ContentWrapper>
            <S.ImageWrapper>
              <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
            </S.ImageWrapper>
            <Auth.FormTitle>{t('verifyAccountForm.title')}</Auth.FormTitle>
            <S.VerifyEmailDescription>{`${t('verifyAccountForm.verifCodeSent')} ${
              verifyInfo?.mail || t('verifyAccountForm.yourMail')
            }`}</S.VerifyEmailDescription>
            {/* <S.ResendContainer>
            <span>{t('verifyAccountForm.noCode')}</span>
            {isResend ? (
              <span className="resend">{t('verifyAccountForm.resend')}</span>
            ) : (
              <Countdown
                format="mm:ss"
                value={
                  verifyInfo?.dateSend
                    ? new Date(verifyInfo.dateSend).getTime() + 1000 * 60 * 5
                    : new Date().getTime() + 1000 * 60 * 5
                }
                onFinish={() => setIsResend(true)}
              />
            )}
          </S.ResendContainer> */}
          </S.ContentWrapper>
        </BaseForm>
      </Auth.FormWrapper>
    </AuthLayout>
  );
};
